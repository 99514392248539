import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OrganisationTeam from "./OrganisationTeam";
import OutreachCommunicationUser from "./OutreachCommunicationUser";
import Role from "./Role";
import StreamsUser from "./StreamsUser";
import UserRequest from "./UserRequest";
import UsersOrganisationTeam from "./UsersOrganisationTeam";
import XeroAuthorisation from "./XeroAuthorisation";

@Model()
class User extends ApplicationRecord {
  static jsonapiType = "users";

  static active() {
    return this.where({ active: true });
  }

  @BelongsTo() operationalTeam: OrganisationTeam;

  @BelongsTo() organisation: Organisation;

  @BelongsTo()
  primaryUsersOrganisationTeam: UsersOrganisationTeam;

  @BelongsTo() primaryOrganisationTeam: OrganisationTeam;

  @BelongsTo() role: Role;

  @BelongsTo() socialRole: Role;

  @BelongsTo() outreachRole: Role;

  @BelongsTo() xeroAuthorisation: XeroAuthorisation;

  @HasMany() streamsUsers: StreamsUser[];

  @HasMany() outreachCommunicationUsers: OutreachCommunicationUser[];

  @HasMany() userRequest: UserRequest[];

  @Attr() accessLevel: string;

  @Attr() active: boolean;

  @Attr() admin: boolean;

  @Attr() currentSignInAt: string;

  @Attr() cslRole: string;

  @Attr() deletionWarnings: string[];

  @Attr() email: string;

  @Attr() firstName: string;

  @Attr() hidden: boolean;

  @Attr() highValue: boolean;

  @Attr() identityProvider: string;

  @Attr() intercomVisibility: string;

  @Attr() lastName: string;

  @Attr() name: string;

  @Attr() notes: string;

  @Attr() operationalTeamId: number;

  @Attr() organisationId: number;

  @Attr() transferableAssociations: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr() briefSetIds: number[];

  @Attr() externalId: number;

  @Attr() externalType: string;

  @Attr() language: string;

  @Attr() outreachRoleId: number;

  @Attr() phoneCountryCode: string;

  @Attr() phoneNumber: string;

  @Attr() pictureUrl: string;

  @Attr() qcModerator: boolean;

  @Attr() roleId: number;

  @Attr() secondaryTeamIds: number[];

  @Attr() sentimentModerator: boolean;

  @Attr() signInCount: number;

  @Attr() socialRoleId: number;

  @Attr() teamName: string;

  @Attr({ persist: false }) mentionStreamCount: number;

  @Attr({ persist: false }) socialStreamCount: number;

  @Attr() organisationTeams: UsersOrganisationTeam[];

  @Attr() requests: UserRequest[];

  @Attr() timeZone: string;

  @Attr() twoFactorAuthCarrier: string;

  @Attr() twoFactorAuthEnabled: boolean;

  @Attr() welcomeSentAt: string;

  @Attr() xeroOauth2AuthorisationUrl: string;

  @Attr({ persist: false }) secondaryTeamNames: string[];

  @Attr({ persist: false }) other_organisations: Organisation[];

  assignPrimaryTeam(teamId: number) {
    if (teamId) {
      if (this.primaryUsersOrganisationTeam) {
        this.primaryUsersOrganisationTeam.organisationTeamId = teamId;
      } else {
        this.primaryUsersOrganisationTeam = new UsersOrganisationTeam({
          organisationTeamId: teamId,
          primary: true,
        });
      }
    } else if (this.primaryUsersOrganisationTeam) {
      this.primaryUsersOrganisationTeam.isMarkedForDestruction = true;
    }
  }

  primaryOrganisationTeamId() {
    return (
      this.primaryUsersOrganisationTeam?.organisationTeamId ||
      this.primaryOrganisationTeam?.id
    );
  }

  primaryOrganisationTeamName(defaultValue: string) {
    return this.primaryOrganisationTeam?.name || defaultValue;
  }
}

export default User;
