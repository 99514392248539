import { nextTick } from "vue";
import { createI18n } from "vue-i18n";

import { defaultLocale } from "shared/boot/locale";
import { updateBaseURL } from "shared/helpers/axios";
import en from "shared/i18n/en_AU.json";
import appConfig from "shared/services/appConfig";
import StorageService from "shared/services/StorageService";

let i18n;

export function getI18n() {
  if (!i18n) {
    i18n = createI18n({
      locale: defaultLocale(),
      fallbackLocale: "en-AU",
      silentFallbackWarn: true,
      globalInjection: true,
      warnHtmlInMessage: "off",
      messages: {
        "en-AU": en,
      },
      numberFormats: {
        en: {
          suffix: {
            notation: "compact",
            compactDisplay: "short",
          },
          currency: {
            style: "currency",
            currency: "AUD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
          percentage: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
        "en-AU": {
          suffix: {
            notation: "compact",
            compactDisplay: "short",
          },
          currency: {
            style: "currency",
            currency: "AUD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
          percentage: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
        "en-CA": {
          suffix: {
            notation: "compact",
            compactDisplay: "short",
          },
          currency: {
            style: "currency",
            currency: "CAD",
            minimumFractionDigits: 0,
          },
          percentage: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
        "en-GB": {
          suffix: {
            notation: "compact",
            compactDisplay: "short",
          },
          currency: {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 0,
          },
          percentage: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
        "en-US": {
          suffix: {
            notation: "compact",
            compactDisplay: "short",
          },
          currency: {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
          },
          percentage: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
        "fr-CA": {
          suffix: {
            notation: "compact",
            compactDisplay: "short",
          },
          currency: {
            style: "currency",
            currency: "CAD",
            minimumFractionDigits: 0,
          },
          percentage: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
        de: {
          suffix: {
            notation: "compact",
            compactDisplay: "short",
          },
          currency: {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
          },
          percentage: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    });
  }

  return i18n;
}

export const SUPPORTED_LOCALES = [
  "en-AU",
  "en-CA",
  "en-GB",
  "en-US",
  "en",
  "es",
  "fr-CA",
  "fr",
  "it",
  "zh_CN",
  "de",
];

export async function loadLocaleMessages(locale) {
  const messages = (await import(`../i18n/${locale.replace("-", "_")}.json`))
    .default;

  getI18n().global.setLocaleMessage(locale, messages);

  return nextTick();
}

export async function setLocale(newLocale) {
  const locale = newLocale || defaultLocale();
  const baseLocale = locale.split("-")[0];
  const locales = [locale, baseLocale];

  const targetLocale = SUPPORTED_LOCALES.find((supportedLocale) =>
    locales.includes(supportedLocale)
  );

  if (targetLocale) {
    if (!getI18n().global.availableLocales.includes(targetLocale)) {
      await loadLocaleMessages(targetLocale).catch(() => {});
    }

    getI18n().global.locale = targetLocale;
  }
}

export function getLocaleText(key, values) {
  return getI18n().global.t(key, values);
}

export function getLocaleNumber(number, option, locale = null) {
  return getI18n().global.n(number, option, locale);
}

export function getLocalePluralText(key, count, values) {
  return getI18n().global.tc(key, count, values);
}

export function getLocaleValue(key) {
  return getI18n().global.tm(key);
}

export function getLocaleDate(date, options) {
  return getI18n().global.d(date, options);
}

export function getLocale() {
  return getI18n().global.locale;
}

export default async ({ app }) => {
  app.use(getI18n());

  await setLocale(await StorageService.get("locale"));

  if (app.config.globalProperties.$isMobile) {
    const config = appConfig.getConfig(import.meta.env.VITE_REGION);
    updateBaseURL(
      app.config.globalProperties.$streemApiV1.client,
      config.baseUrl
    );
  }
};
