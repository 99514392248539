<template>
  <BaseButtonDropdown
    v-if="showSentiment"
    v-model="visible"
    new-dropdown
    class="sentiment-widget"
    placement="bottom-start"
  >
    <template #btn="{ toggle }">
      <span>
        <BaseButton
          new-design
          :aria-label="$t('sentiment_picker.toggle_sentiment')"
          :label="sentimentOption.label"
          :icon="sentimentOption.icon"
          class="sentiment-widget__button"
          :style="sentimentOption.style"
          icon-size="sm"
          dense
          @click.stop="toggleDropdown(toggle)"
        />
        <StreemTooltip v-if="sentimentRatingTooltip">
          {{ sentimentRatingTooltip }}
        </StreemTooltip>
      </span>
    </template>

    <div
      v-if="allowSentimentRating"
      class="sentiment-widget__menu"
      @click.stop
    >
      <div class="sentiment-widget__heading">
        {{ $t("sentiment_widget.heading") }}
      </div>
      <div
        v-for="option in sentimentRatingOptions"
        :key="option.value"
        class="sentiment-widget__option"
      >
        <Radio
          new-design
          :model-value="sentimentOption.field"
          :value="option.field"
          @update:model-value="updateSentiment(option)"
        >
          <span class="q-ml-sm">{{ option.label }}</span>
          <span
            v-if="
              mentionSentimentOption &&
              mentionSentimentOption.field === option.field
            "
            class="q-ml-sm soft"
          >
            {{ $t("sentiment_widget.automated") }}
          </span>
        </Radio>
      </div>
      <div
        v-if="sentimentRatingTooltip"
        class="sentiment-widget__update"
      >
        {{ sentimentRatingTooltip }}
      </div>
    </div>
  </BaseButtonDropdown>
</template>

<script setup>
import { ref } from "vue";

import { BaseButton, BaseButtonDropdown } from "shared/components/base";
import StreemTooltip from "shared/components/base/StreemTooltip.vue";
import Radio from "shared/components/core/forms/Radio.vue";
import useMentionSentiment from "shared/composables/useMentionSentiment";

const props = defineProps({
  mention: {
    type: Object,
    required: true,
  },
  stream: {
    type: Object,
    required: true,
  },
});

const {
  sentimentRatingOptions,
  sentimentOption,
  mentionSentimentOption,
  sentimentRatingTooltip,
  saveSentimentRating,
  showSentiment,
  allowSentimentRating,
} = useMentionSentiment(props);

const visible = ref(false);

function toggleDropdown(toggle) {
  if (allowSentimentRating.value) toggle();
}

function updateSentiment(option) {
  saveSentimentRating(option.value);
  visible.value = false;
}
</script>

<style lang="scss" scoped>
.sentiment-widget {
  color: var(--s-color-font-default);

  &__button {
    --icon-color: var(--s-color-denim-4);

    :deep(.base-button__icon) {
      color: var(--icon-color);
    }
  }

  &__heading {
    font-weight: var(--s-font-weight-bold);
  }

  &__menu {
    padding-block: var(--s-spacing-md);
    padding-inline: var(--s-spacing-lg);
  }

  &__update {
    padding-top: var(--s-spacing-md);
    font-size: var(--s-font-size-sm);
    color: var(--s-color-font-subtext);
  }
}
</style>
