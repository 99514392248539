import add from "date-fns/add";
import addSeconds from "date-fns/addSeconds";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import { Attr, BelongsTo, Model } from "spraypaint";

import { getLocalePluralText, getLocaleText } from "shared/boot/i18n";
import {
  DAY_IN_MILLISECONDS,
  MINUTE_IN_MILLISECONDS,
  shortWeekdays,
  weekdays,
} from "shared/constants";
import { toSentence } from "shared/helpers/array";
import {
  endOfDateInTimezone,
  formatDate,
  fromCurrentToGivenTimezone,
  getDateTimeZoneOffset,
  getTimeFromSeconds,
  getTimezone,
  parseDate,
  secondsWithTimezone,
  shortTimezone,
  swapTimezones,
  unixDateTime,
} from "shared/helpers/date";
import {
  ApplicationRecord,
  objectDirtyChecker,
} from "shared/services/spraypaint";

import ScheduledReportSpecification from "./ScheduledReportSpecification";

function weekParity(datetime: Date, timezone: string) {
  let timestamp = datetime.valueOf();

  const offset = getDateTimeZoneOffset(datetime, timezone);
  timestamp =
    timestamp - 3 * DAY_IN_MILLISECONDS + offset * MINUTE_IN_MILLISECONDS;

  return (timestamp / (7 * DAY_IN_MILLISECONDS)) % 2;
}

function previousWeeklyDeliveryTimes(
  schedule: any,
  timezone: string,
  count: number
) {
  if (weekdays.every((day) => !schedule[`on${day}`])) return [];

  const deliveryTimestamps = [];

  const currentDate = fromCurrentToGivenTimezone(new Date(), timezone);

  let startingDay = currentDate.getDay();
  const startingDate = currentDate.getDate();
  const startingYear = currentDate.getFullYear();
  const startingMonth = currentDate.getMonth();
  let daysBack = 0;

  const localTimezone = getTimezone();

  do {
    const dayName = weekdays[startingDay];

    if (schedule[`on${dayName}`]) {
      let deliveryDate = new Date(startingYear, startingMonth);
      deliveryDate.setDate(startingDate - daysBack);
      deliveryDate = addSeconds(deliveryDate, schedule.deliverAt);

      const deliveryTime = swapTimezones(deliveryDate, timezone, localTimezone);

      deliveryTimestamps.push(unixDateTime(deliveryTime));
    }

    startingDay = startingDay === 0 ? 6 : startingDay - 1;
    daysBack += 1;
  } while (deliveryTimestamps.length < count);

  return deliveryTimestamps;
}

function previousFortnightlyDeliveryTimes(
  schedule: any,
  timezone: string,
  count: number
) {
  const currentDate = new Date();
  const startDate = fromCurrentToGivenTimezone(
    new Date(schedule.fortnightStartDate),
    timezone
  );

  const deliveryTimestamps = [];

  const startingDate = currentDate.getDate();
  const startingYear = currentDate.getFullYear();
  const startingMonth = currentDate.getMonth();

  // get start week
  const dayDiff = currentDate.getDay() - startDate.getDay();
  let daysBack = dayDiff >= 0 ? dayDiff : 7 + dayDiff;
  const lastDeliveryDate = new Date(startingYear, startingMonth);
  lastDeliveryDate.setDate(startingDate - daysBack);

  const startDateParity = weekParity(startDate, timezone);

  if (startDateParity !== weekParity(lastDeliveryDate, timezone)) {
    daysBack += 7;
  }

  const localTimezone = getTimezone();

  do {
    let deliveryDate = new Date(startingYear, startingMonth);
    deliveryDate.setDate(startingDate - daysBack);
    deliveryDate = addSeconds(deliveryDate, schedule.deliverAt);

    const deliveryTime = swapTimezones(deliveryDate, timezone, localTimezone);

    deliveryTimestamps.push(unixDateTime(deliveryTime));

    daysBack += 14;
  } while (deliveryTimestamps.length < count);

  return deliveryTimestamps;
}

function previouMonthlyDeliveryTimes(
  schedule: any,
  timezone: string,
  count: number
) {
  const deliveryTimestamps = [];

  const currentDate = new Date();
  const startingDate = currentDate.getDate();
  const startingYear = currentDate.getFullYear();
  const startingMonth = currentDate.getMonth();
  const validDays = new Set(schedule.daysOfMonth);
  let daysBack = 0;

  const localTimezone = getTimezone();

  do {
    let deliveryDate = new Date(startingYear, startingMonth);
    deliveryDate.setDate(startingDate - daysBack);

    if (
      validDays.has(deliveryDate.getDate()) ||
      (schedule.onLastDay &&
        endOfDateInTimezone(deliveryDate, "month").getDate() ===
          deliveryDate.getDate())
    ) {
      deliveryDate = addSeconds(deliveryDate, schedule.deliverAt);

      const deliveryTime = swapTimezones(deliveryDate, timezone, localTimezone);

      deliveryTimestamps.push(unixDateTime(deliveryTime));
    }

    daysBack += 1;
  } while (deliveryTimestamps.length < count);

  return deliveryTimestamps;
}

@Model()
class ScheduledReportSpecificationSchedule extends ApplicationRecord {
  static jsonapiType = "scheduled_report_specification_schedules";

  @BelongsTo()
  scheduledReportSpecification: ScheduledReportSpecification;

  @Attr() deliverAt: number;

  @Attr() deliverEvery: number;

  @Attr() deliverTo: number;

  @Attr() frequency: string;

  @Attr() sinceLastMonth: boolean;

  @Attr() sinceThisMonth: boolean;

  @Attr() onMonday: boolean;

  @Attr() onTuesday: boolean;

  @Attr() onWednesday: boolean;

  @Attr() onThursday: boolean;

  @Attr() onFriday: boolean;

  @Attr() onSaturday: boolean;

  @Attr() onSunday: boolean;

  @Attr({ dirtyChecker: objectDirtyChecker }) daysOfMonth: number[];

  @Attr() fortnightStartDate: string;

  @Attr() onLastDay: boolean;

  @Attr() contentRangeDay: number;

  @Attr() contentRangeHour: number;

  @Attr() contentRangeMinute: number;

  deliveryTime({
    timezone = getTimezone(),
    location = "",
    displayTimezone = false,
  } = {}) {
    if (this.frequency === "once") {
      const date = secondsWithTimezone(this.deliverAt, timezone);
      const shortTzOrLocation = displayTimezone
        ? shortTimezone(date, timezone)
        : location;

      return `${formatDate(
        date,
        "d MMM yyyy, h:mmaa"
      )} ${shortTzOrLocation}`.trim();
    }

    const shortTzOrLocation = displayTimezone
      ? shortTimezone(new Date(), timezone)
      : location;

    if (this.deliverAt >= 0 && !this.deliverTo) {
      const date = secondsWithTimezone(this.deliverAt, timezone);

      return `${getTimeFromSeconds(
        date,
        "hh:mmaa"
      )} ${shortTzOrLocation}`.trim();
    }

    if (this.deliverAt >= 0 && this.deliverTo) {
      const deliverAtTime = secondsWithTimezone(this.deliverAt, timezone);
      const deliverToTime = secondsWithTimezone(this.deliverTo, timezone);

      const from = getTimeFromSeconds(deliverAtTime, "hh:mmaa");
      const to = getTimeFromSeconds(deliverToTime, "hh:mmaa");

      return getLocaleText(
        "resources.scheduled_report_specification_schedule.delivery_time",
        { from, to, tz: shortTzOrLocation }
      ).trim();
    }

    return "-";
  }

  deliveryDays({ timezone = getTimezone(), shortDays = false } = {}) {
    if (this.frequency === "once") {
      return getLocaleText(
        "resources.scheduled_report_specification_schedule.delivery_days_once"
      );
    }

    if (this.frequency === "fortnightly") {
      const date = fromCurrentToGivenTimezone(
        new Date(this.fortnightStartDate),
        timezone
      );
      const formattedDate = formatDate(date, shortDays ? "EE" : "EEEE");

      return getLocaleText(
        "resources.scheduled_report_specification_schedule.delivery_days_fortnightly",
        { formattedDate }
      );
    }

    if (this.frequency === "weekly") {
      const days = new Set();

      weekdays.forEach((day, index) => {
        if ((this as any)[`on${day}`]) {
          days.add(shortDays ? shortWeekdays[index] : day);
        }
      });

      const sourceDays = shortDays ? shortWeekdays : weekdays;

      if (sourceDays.every((weekday) => days.has(weekday))) {
        return getLocaleText(
          "resources.scheduled_report_specification_schedule.delivery_days_every_day"
        );
      }

      const saturday = sourceDays[6];
      const sunday = sourceDays[0];

      if (days.has(saturday) && days.has(sunday)) {
        days.delete(saturday);
        days.delete(sunday);
        days.add("Weekends");
      }

      const weekdayNames = sourceDays.slice(1, 6);

      if (weekdayNames.every((weekday) => days.has(weekday))) {
        weekdayNames.forEach((weekday) => days.delete(weekday));
        days.add("Weekdays");
      }

      const daysToSentence = toSentence(
        Array.from(days) as string[],
        getLocaleText("global.words_separator"),
        getLocaleText("global.words_joiner")
      );

      return getLocaleText(
        "resources.scheduled_report_specification_schedule.delivery_days_weekly",
        { days: daysToSentence }
      );
    }

    return getLocaleText(
      "resources.scheduled_report_specification_schedule.delivery_days_monthly",
      {
        days: [...this.daysOfMonth, ...(this.onLastDay ? ["last"] : [])].join(),
      }
    );
  }

  mentionsPeriod() {
    if (this.sinceThisMonth) {
      return getLocaleText(
        "resources.scheduled_report_specification_schedule.this_month"
      );
    }

    if (this.sinceLastMonth) {
      return getLocaleText(
        "resources.scheduled_report_specification_schedule.last_month"
      );
    }

    const distanceFn =
      this.contentRangeDay > 0 ? differenceInDays : differenceInHours;

    const currentTime = new Date();
    const period = distanceFn(
      add(currentTime, {
        days: this.contentRangeDay,
        hours: this.contentRangeHour,
        minutes: this.contentRangeMinute,
      }),
      currentTime
    );

    return this.contentRangeDay > 0
      ? getLocalePluralText(
          "resources.scheduled_report_specification_schedule.day",
          period,
          { period }
        )
      : getLocalePluralText(
          "resources.scheduled_report_specification_schedule.hour",
          period,
          { period }
        );
  }

  previousDeliveryTimestamps(
    count: number,
    { timezone = getTimezone() as string } = {}
  ) {
    switch (this.frequency) {
      case "once":
        return parseDate(this.deliverAt) <= new Date() ? [this.deliverAt] : [];
      case "weekly":
        return previousWeeklyDeliveryTimes(this, timezone, count);
      case "fortnightly":
        return previousFortnightlyDeliveryTimes(this, timezone, count);
      case "monthly":
        return previouMonthlyDeliveryTimes(this, timezone, count);
      default:
        return [];
    }
  }
}

export default ScheduledReportSpecificationSchedule;
