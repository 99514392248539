import {
  organisationFeatures,
  outreachFeatures,
  socialFeatures,
} from "shared/boot/authorization";
import { showLoadErrorPage } from "shared/boot/errors";
import { getLocaleText } from "shared/boot/i18n";
import useProductMenu from "shared/composables/useProductMenu";
import useTeamRoute from "shared/composables/useTeamRoute";
import useTeamRouterViewKey from "shared/composables/useTeamRouterViewKey";
import { streamTypes } from "shared/constants";
import applyThemeConfig from "shared/helpers/applyThemeConfig";
import { typecastRouteProps } from "shared/helpers/routing";
import transformKeys from "shared/helpers/transformKeys";
import features from "shared/services/features";
import { useStreamGroupsStore } from "shared/stores/streamGroups";
import { useStreamsStore } from "shared/stores/streams";
import { useUserStore } from "shared/stores/user";

export default applyThemeConfig([
  {
    name: "login",
    path: "/sign-in",
    component: () => import("src/pages/Login.vue"),
    alias: "/login",
    meta: {
      // This var means a not logged user can access the route. Used by the navigation guard
      freeAccess: true,
    },
    props: (route) => ({
      redirect: route.query.redirect,
      resetKey: route.query.reset_key,
    }),
  },
  {
    path: "/oauth/:type",
    component: () => import("src/components/SocialEngageOAuth.vue"),
    props: (route) => ({
      ...route.params,
      ...transformKeys(route.query, "camelCase"),
    }),
    meta: {
      freeAccess: true,
      public: true,
    },
  },
  {
    path: "/",
    component: () => import("src/layouts/DefaultLayout.vue"),
    children: [
      {
        path: "",
        name: "launchpad",
        component: () =>
          import("src/pages/MainPage.vue").catch(showLoadErrorPage),
        props: (route) => ({ ...route.params, ...route.query }),
        meta: {
          title: () => getLocaleText("routes.launchpad"),
          features: {
            hasAll: ["has_media_platform", "has_launchpad"],
          },
        },
      },
      {
        path: "/logout",
        name: "logout",
        component: () =>
          import("src/pages/MainPage.vue").catch(showLoadErrorPage),
        props: true,
      },
      {
        path: "publishers/frontpages",
        name: "front-pages",
        component: () =>
          import("src/pages/Frontpages.vue").catch(showLoadErrorPage),
        meta: {
          features: {
            has: "has_front_pages",
          },
          title: () => getLocaleText("routes.front_pages"),
        },
      },
      {
        path: "daily-highlights/:id?",
        name: "daily-highlights",
        component: () =>
          import("src/pages/DailyHighlightsPage.vue").catch(showLoadErrorPage),
        props: true,
        meta: {
          title: () => getLocaleText("routes.daily_highlights"),
          features: {
            has: "has_daily_highlight",
          },
        },
      },
      {
        path: "reports",
        component: () =>
          import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
        meta: {
          title: () => getLocaleText("routes.reports"),
          permissions: {
            hasAny: [
              "can_generate_stream_reports",
              "can_manage_organisation_reports",
              "can_manage_tagged_reports",
              "can_manage_scheduled_reports",
            ],
          },
        },
        children: [
          {
            path: "mention-stream-report/:selectedStreamId?",
            name: "mention-stream-report",
            component: () =>
              import("src/pages/MentionStreamReport.vue").catch(
                showLoadErrorPage
              ),
            props: true,
            meta: {
              permissions: {
                has: "can_generate_stream_reports",
              },
              title: () => getLocaleText("routes.mention_stream_report"),
            },
          },
          {
            path: "curated-email-report/audience",
            name: "curated-email-report-aggregate-audience",
            component: () =>
              import(
                "src/components/OrganisationReportAudienceDetail/OrganisationReportAudienceDetailAggregate.vue"
              ).catch(showLoadErrorPage),
            meta: {
              permissions: {
                has: "can_view_der_audience",
              },
              title: () =>
                getLocaleText("routes.curated_email_report_audience"),
            },
          },
          {
            path: "curated-email-report/:editedReportId?",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            props: (route) => ({ ...route.params, ...route.query }),
            meta: {
              permissions: {
                has: "can_manage_organisation_reports",
              },
              title: () => getLocaleText("routes.curated_email_report"),
            },
            children: [
              {
                path: "audience",
                name: "curated-email-report-audience",
                component: () =>
                  import(
                    "src/components/OrganisationReportAudienceDetail/OrganisationReportAudienceDetailSingle.vue"
                  ).catch(showLoadErrorPage),
                props: true,
                meta: {
                  permissions: {
                    has: "can_view_der_audience",
                  },
                  title: () =>
                    getLocaleText(
                      "routes.curated_email_report_audience_overview"
                    ),
                },
              },
              {
                path: "",
                name: "curated-email-report",
                component: () =>
                  import("src/pages/OrganisationReport.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({ ...route.params, ...route.query }),
                beforeEnter() {
                  useTeamRouterViewKey().setEmptyKey();
                },
              },
            ],
          },
          {
            path: "scheduled-report-specifications/:specId?",
            name: "scheduled-report",
            component: () =>
              import("src/pages/ScheduledReport.vue").catch(showLoadErrorPage),
            props: true,
            beforeEnter() {
              useTeamRouterViewKey().setEmptyKey();
            },
            meta: {
              permissions: {
                has: "can_manage_scheduled_reports",
              },
              title: () => getLocaleText("routes.scheduled_report"),
            },
          },
          {
            path: "tagged-mentions-report",
            name: "tagged-mentions-report",
            component: () =>
              import("src/pages/TaggedMentionsReport.vue").catch(
                showLoadErrorPage
              ),
            props: true,
            meta: {
              permissions: {
                has: "can_manage_tagged_reports",
              },
              title: () => getLocaleText("routes.tagged_folders_report"),
            },
          },
          {
            name: "reports",
            path: "",
            component: () =>
              import("src/pages/Reports.vue").catch(showLoadErrorPage),
            props: (route) => ({ view: route.query.view }),
          },
        ],
      },
      {
        path: "instant-insights",
        name: "instant-insights",
        component: () =>
          import("src/pages/InstantInsights.vue").catch(showLoadErrorPage),
        meta: {
          title: () => getLocaleText("routes.instant_insights"),
        },
        beforeEnter() {
          useTeamRouterViewKey().setEmptyKey();
        },
      },
      {
        path: "media-and-transcripts",
        name: "media-and-transcripts",
        component: () =>
          import("src/pages/MediaTranscriptRequests.vue").catch(
            showLoadErrorPage
          ),
        meta: {
          title: () =>
            features.has("has_completed_transcripts_page")
              ? getLocaleText("routes.media_and_transcripts")
              : getLocaleText("routes.media"),
        },
      },
      {
        path: "outreach",
        name: "outreach",
        component: () => import("src/pages/Outreach").catch(showLoadErrorPage),
        beforeEnter: (to, from, next) => {
          if (outreachFeatures.canViewOutreachPlatform()) {
            if (to.path.replace(/\/$/, "") === "/outreach") {
              const name = features.has("has_outreach_2")
                ? "outreach-launchpad"
                : "outreach-campaigns";

              return next({ name });
            }

            return next();
          }

          return next({ name: "launchpad" });
        },
        children: [
          {
            path: "campaigns",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.campaigns"),
            },
            children: [
              {
                path: "new",
                name: "outreach-campaign-new",
                component: () =>
                  import("src/pages/Outreach/OutreachCampaignEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
                meta: {
                  title: () => getLocaleText("global.new"),
                },
              },
              {
                path: ":id/edit",
                name: "outreach-campaign-edit",
                component: () =>
                  import("src/pages/Outreach/OutreachCampaignEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
                meta: {
                  title: () => getLocaleText("global.edit"),
                },
              },
              {
                path: ":id/analytics",
                name: "outreach-campaign-analytics",
                component: () =>
                  import(
                    "src/pages/Outreach/OutreachCampaignAnalytics.vue"
                  ).catch(showLoadErrorPage),
                props: true,
                meta: {
                  title: (route) =>
                    route.query.subject
                      ? getLocaleText("routes.campaign_analytics", {
                          subject: route.query.subject,
                        })
                      : getLocaleText("routes.analytics"),
                },
              },
              {
                path: "",
                name: "outreach-campaigns",
                component: () =>
                  import("src/pages/Outreach/OutreachTabCampaigns.vue").catch(
                    showLoadErrorPage
                  ),
              },
            ],
          },
          {
            path: "recipient-lists",
            name: "outreach-recipient-lists",
            component: () =>
              import("src/pages/Outreach/OutreachTabRecipientLists.vue").catch(
                showLoadErrorPage
              ),
            props: (route) => ({ ...route.params, ...route.query }),
            meta: {
              title: () => getLocaleText("routes.recipient_lists"),
            },
          },
          {
            path: "launchpad",
            name: "outreach-launchpad",
            component: () =>
              import("src/pages/Outreach/OutreachLaunchpad.vue").catch(
                showLoadErrorPage
              ),
            props: (route) => ({ ...route.params, ...route.query }),
            meta: {
              title: () => getLocaleText("routes.outreach_launchpad"),
              hideSearchBar: true,
            },
          },
          {
            path: "search",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.outreach_search"),
              hideSearchBar: true,
            },
            beforeEnter(to, from, next) {
              return outreachFeatures.quickSearch().isDisabled
                ? next({ name: "outreach-launchpad" })
                : next();
            },
            children: [
              {
                path: "outlet/:id/:tab?",
                name: "outreach-search-outlet",
                beforeEnter(to, from, next) {
                  if (Number(to.params.id) === 0) {
                    return next({ name: "outreach-search" });
                  }

                  return next();
                },
                component: () =>
                  import("src/pages/Outreach/OutreachOutlet.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({
                  id: route.params.id,
                  tab: route.params.tab || "about",
                }),
                meta: {
                  title: (route) =>
                    route.params.name || getLocaleText("global.outlet"),
                  reuseComponent: true,
                },
              },
              {
                path: "influencer/:id/:tab?",
                name: "outreach-search-influencer",
                component: () =>
                  import("src/pages/Outreach/OutreachInfluencer.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({
                  id: route.params.id,
                  tab: route.params.tab || "about",
                }),
                meta: {
                  title: (route) =>
                    route.params.name || getLocaleText("global.journalist"),
                  reuseComponent: true,
                },
              },
              {
                path: "",
                name: "outreach-search",
                component: () =>
                  import("src/pages/Outreach/OutreachSearch.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({ ...route.params, ...route.query }),
                beforeEnter(to, from, next) {
                  return outreachFeatures.search().isDisabled
                    ? next({ name: "outreach-launchpad" })
                    : next();
                },
              },
            ],
          },
          {
            path: "activities",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.outreach_activities"),
              hideSearchBar: true,
            },
            beforeEnter(to, from, next) {
              return outreachFeatures.activities().isDisabled
                ? next({ name: "outreach-launchpad" })
                : next();
            },
            children: [
              {
                path: "pitch/:id",
                name: "pitch-edit",
                component: () =>
                  import("src/pages/Outreach/OutreachPitchEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({ ...route.params, ...route.query }),
                meta: {
                  title: getLocaleText("routes.edit_pitch"),
                },
                beforeEnter(to, from, next) {
                  return outreachFeatures.sendMediaRelease().isDisabled
                    ? next({ name: "outreach-activities" })
                    : next();
                },
              },
              {
                path: "media-release/new",
                name: "media-release-new",
                component: () =>
                  import("src/pages/Outreach/MediaReleaseEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
                meta: {
                  title: () => getLocaleText("routes.create_media_release"),
                },
                beforeEnter(to, from, next) {
                  return outreachFeatures.sendMediaRelease().isDisabled
                    ? next({ name: "outreach-activities" })
                    : next();
                },
              },
              {
                path: "media-release/:id",
                name: "media-release-edit",
                component: () =>
                  import("src/pages/Outreach/MediaReleaseEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({ ...route.params, ...route.query }),
                meta: {
                  title: () => getLocaleText("routes.edit_media_release"),
                },
                beforeEnter(to, from, next) {
                  return outreachFeatures.sendMediaRelease().isDisabled
                    ? next({ name: "outreach-activities" })
                    : next();
                },
              },
              {
                path: ":id/analytics",
                name: "media-release-analytics",
                component: () =>
                  import(
                    "src/pages/Outreach/OutreachMediaReleaseAnalytics.vue"
                  ).catch(showLoadErrorPage),
                props: (route) => ({ ...route.params, ...route.query }),
                meta: {
                  title: (route) =>
                    route.query.subject
                      ? route.query.subject
                      : getLocaleText("global.analytics"),
                },
                beforeEnter(to, from, next) {
                  return outreachFeatures.mediaReleaseAnalytics().isDisabled
                    ? next({ name: "outreach-activities" })
                    : next();
                },
              },
              {
                path: "",
                name: "outreach-activities",
                props: (route) => ({ ...route.params, ...route.query }),
                component: () =>
                  import("src/pages/Outreach/OutreachActivities.vue").catch(
                    showLoadErrorPage
                  ),
              },
            ],
          },
          {
            path: "lists",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.outreach_lists"),
              hideSearchBar: true,
            },
            beforeEnter(to, from, next) {
              return outreachFeatures.contactLists().isDisabled
                ? next({ name: "outreach-launchpad" })
                : next();
            },
            children: [
              {
                path: ":listId/contacts",
                name: "outreach-lists-contacts",
                component: () =>
                  import(
                    "src/pages/Outreach/OutreachContactListsContacts.vue"
                  ).catch(showLoadErrorPage),
                props: true,
                meta: {
                  title: (route) => {
                    if (route.query.listLabel) {
                      return route.query.listLabel;
                    }

                    return getLocaleText("routes.outreach_contacts");
                  },
                },
              },
              {
                path: "mailing-lists/:contactMailingListId/contacts",
                name: "outreach-mailing-lists-contacts",
                component: () =>
                  import(
                    "src/pages/Outreach/OutreachContactMailingListsContacts.vue"
                  ).catch(showLoadErrorPage),
                props: true,
                meta: {
                  title: (route) => {
                    if (route.query.listLabel) {
                      return route.query.listLabel;
                    }

                    return getLocaleText("routes.outreach_contacts");
                  },
                },
              },
              {
                path: ":tab?",
                name: "outreach-lists",
                component: () =>
                  import("src/pages/Outreach/OutreachLists.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({ ...route.params, ...route.query }),
              },
            ],
          },
        ],
      },
      {
        path: "social",
        name: "social",
        component: () => import("src/pages/Social").catch(showLoadErrorPage),
        meta: {
          product: "social",
          canAccess() {
            return socialFeatures.canViewSocialPlatform();
          },
        },
        children: [
          {
            path: "stream-notifications",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.notifications"),
            },
            children: [
              { path: "", redirect: { name: "social-stream-notifications" } },
              {
                path: "",
                name: "social-stream-notifications",
                component: () =>
                  import("src/pages/StreamNotifications.vue").catch(
                    showLoadErrorPage
                  ),
              },
              {
                path: "notification/:notificationId?",
                name: "social-stream-notifications-edit",
                component: () =>
                  import("shared/pages/StreamNotificationsEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => typecastRouteProps(route),
                meta: {
                  title: (route) =>
                    route.params.notificationId
                      ? getLocaleText("routes.notification_edit")
                      : getLocaleText("routes.notification_create"),
                },
              },
            ],
          },
          {
            path: "monitor",
            component: () => import("src/layouts/BaseLayout.vue"),
            meta: {
              canAccess() {
                return socialFeatures.canViewSocialListen();
              },
              title: (route) => {
                if (!route.params.groupSlug)
                  return getLocaleText("routes.listen");

                return null;
              },
            },
            children: [
              {
                path: "new",
                name: "social-stream-new",
                component: () =>
                  import("src/pages/MentionStreamBuilderPage.vue").catch(
                    showLoadErrorPage
                  ),
                props: (route) => ({
                  streamType: streamTypes.socialStream,
                  ...route.params,
                }),
                meta: {
                  title: () => getLocaleText("routes.social_stream_create"),
                },
              },
              {
                path: ":groupSlug?",
                component: () => import("src/layouts/BaseLayout.vue"),
                beforeEnter(to, from, next) {
                  if (
                    useTeamRoute(to).isRouteRestricted(
                      useStreamGroupsStore().getStreamGroupBySlug(
                        to.params.groupSlug
                      )?.organisation_team_id
                    )
                  ) {
                    next(useProductMenu(true).socialRoute.value);
                  } else {
                    next();
                  }
                },
                meta: {
                  title: (route) => {
                    if (route.params.groupSlug === "shared") {
                      return getLocaleText("routes.shared_social_streams");
                    }

                    const streamGroup =
                      useStreamGroupsStore().getStreamGroupBySlug(
                        route.params.groupSlug
                      );

                    if (streamGroup) {
                      return streamGroup.label;
                    }

                    return getLocaleText("routes.listen");
                  },
                },
                children: [
                  {
                    path: "new",
                    name: "social-group-stream-new",
                    component: () =>
                      import("src/pages/MentionStreamBuilderPage.vue").catch(
                        showLoadErrorPage
                      ),
                    props: (route) => ({
                      streamType: streamTypes.socialStream,
                      ...route.params,
                    }),
                    meta: {
                      title: () => getLocaleText("routes.social_stream_create"),
                    },
                  },
                  {
                    path: "edit",
                    name: "social-stream-group-edit",
                    component: () =>
                      import("src/pages/Social/SocialStreamGroupEdit.vue"),
                    props: true,
                    meta: {
                      parent: "stream",
                      title: () => getLocaleText("routes.social_group_edit"),
                    },
                  },
                  {
                    path: ":streamSlug?",
                    component: () => import("src/layouts/BaseLayout.vue"),
                    meta: {
                      title: (route) => {
                        const stream = useStreamsStore().getStreamBySlug(
                          route.params.streamSlug
                        );

                        if (!stream) {
                          return null;
                        }

                        return stream.label;
                      },
                    },
                    children: [
                      {
                        path: "report/:selectedStreamId",
                        name: "social-report",
                        component: () => import("src/components/SocialReport"),
                        props: true,
                        meta: {
                          title: () =>
                            getLocaleText("routes.social_report_create"),
                        },
                      },
                      {
                        path: "edit",
                        component: () =>
                          import("src/layouts/BaseLayout.vue").catch(
                            showLoadErrorPage
                          ),
                        props: true,
                        children: [
                          {
                            path: ":tab?",
                            component: () =>
                              import("src/layouts/BaseLayout.vue").catch(
                                showLoadErrorPage
                              ),
                            meta: {
                              title: () =>
                                getLocaleText("routes.social_stream_edit"),
                            },
                            children: [
                              {
                                path: "notification/:notificationId?",
                                name: "social-stream-notification-edit",
                                component: () =>
                                  import(
                                    "src/pages/Social/SocialStreamNotificationEdit.vue"
                                  ).catch(showLoadErrorPage),
                                props: true,
                                meta: {
                                  title: (route) =>
                                    route.params.notificationId
                                      ? getLocaleText(
                                          "routes.notification_edit"
                                        )
                                      : getLocaleText(
                                          "routes.notification_create"
                                        ),
                                },
                              },
                              {
                                name: "social-stream-edit",
                                path: "",
                                component: () =>
                                  import(
                                    "src/pages/Social/SocialStreamEdit.vue"
                                  ),
                                props: true,
                                meta: {
                                  reuseComponent: true,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        name: "social-monitor",
                        path: "",
                        component: () =>
                          import("src/pages/Social/SocialTabMonitor.vue"),
                        props: (route) => ({
                          ...route.params,
                          shared: route.params.groupSlug === "shared",
                        }),
                        meta: {
                          reuseComponent: true,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "",
                component: () =>
                  import("src/pages/Social/SocialTabMonitor.vue"),
                props: true,
              },
            ],
          },
          {
            path: "engage",
            component: () => import("src/pages/Social/SocialTabEngage.vue"),
            meta: {
              canAccess() {
                return socialFeatures.canViewSocialEngage();
              },
            },
            children: [
              {
                path: "",
                component: () => import("src/layouts/BaseLayout.vue"),
                meta: {
                  title: () => getLocaleText("routes.social_calendar"),
                },
                children: [
                  {
                    path: "compose/:id?",
                    name: "social-engage-compose",
                    component: () =>
                      import("src/components/SocialEngageCompose"),
                    props: (route) => typecastRouteProps(route),
                    meta: {
                      title: () => getLocaleText("routes.social_compose_post"),
                    },
                  },
                  {
                    path: "",
                    name: "social-engage",
                    component: () =>
                      import("src/components/SocialEngageScheduler"),
                    props: (route) => typecastRouteProps(route),
                  },
                ],
              },
              {
                path: "settings",
                name: "social-engage-settings",
                component: () => import("src/components/SocialEngageSettings"),
                props: (route) => ({
                  action: route.query.action,
                }),
                meta: {
                  title: () =>
                    getLocaleText("routes.social_account_management"),
                  canAccess() {
                    return socialFeatures.canManageSocialEngage();
                  },
                },
              },
              {
                path: "post-performance",
                name: "social-post-performance",
                component: () =>
                  import(
                    "src/components/SocialEngagePostPerformance/SocialEngagePostPerformancePage.vue"
                  ),
                meta: {
                  title: () => getLocaleText("routes.social_performance"),
                },
              },
            ],
          },
          {
            name: "social-account",
            path: ":product/account",
            redirect: "profile",
            component: () => import("src/pages/Account.vue"),
            meta: {
              title: () => getLocaleText("routes.account"),
            },
            children: [
              {
                path: "profile",
                component: () =>
                  import("src/pages/AccountFormPage.vue").catch(
                    showLoadErrorPage
                  ),
                meta: {
                  title: () => getLocaleText("routes.profile"),
                },
              },
              {
                path: "preference",
                component: () =>
                  import(
                    "shared/components/user/UserPreferencesForm.vue"
                  ).catch(showLoadErrorPage),
                meta: {
                  title: () => getLocaleText("routes.preferences"),
                },
              },
              {
                path: "security",
                component: () =>
                  import("src/pages/SecurityFormPage.vue").catch(
                    showLoadErrorPage
                  ),
                meta: {
                  title: () => getLocaleText("routes.security"),
                },
              },
            ],
          },
          {
            path: "analyse",
            name: "social-analyse",
            component: () => import("src/pages/Social/SocialTabAnalyse.vue"),
            meta: {
              title: () => getLocaleText("routes.social_analyse"),
              canAccess() {
                return socialFeatures.canViewSocialAnalyse();
              },
            },
            beforeEnter() {
              useTeamRouterViewKey().setEmptyKey();
            },
          },
          {
            path: "reports",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.social_reports"),
              canAccess() {
                return (
                  socialFeatures.canViewSocialAnalyse() ||
                  socialFeatures.canViewSocialListen() ||
                  socialFeatures.canViewSocialEngage()
                );
              },
            },
            children: [
              {
                path: "new",
                name: "social-report-new",
                component: () =>
                  import("src/components/SocialReport").catch(
                    showLoadErrorPage
                  ),
                props: true,
                meta: {
                  title: () => getLocaleText("routes.social_report_create"),
                },
              },
              {
                path: ":preSelectedTabLabel?",
                name: "social-reports",
                component: () =>
                  import("src/pages/Social/SocialReports.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
              },
            ],
          },
          {
            path: "tagged",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            props: true,
            meta: {
              title: () => getLocaleText("routes.tagged_folders"),
              route: (path, route) => {
                if (route.params.groupSlug) {
                  if (route.params.groupSlug === "shared") {
                    return `${path}/my-tagged`;
                  }

                  return `${path}/${route.params.groupSlug}`;
                }

                return path;
              },
            },
            children: [
              {
                path: ":groupSlug?",
                component: () =>
                  import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
                meta: {
                  title: (route) => {
                    if (route.params.groupSlug === "shared")
                      return getLocaleText("routes.shared_streams");
                    if (route.params.groupSlug === "launchpad")
                      return getLocaleText("routes.launchpad");

                    const streamGroup =
                      useStreamGroupsStore().getStreamGroupBySlug(
                        route.params.groupSlug
                      );

                    return streamGroup?.label;
                  },
                  route: (path, route) => {
                    if (route.params.groupSlug === "launchpad") {
                      return "/";
                    }

                    return path;
                  },
                },
                children: [
                  {
                    path: ":streamSlug?",
                    component: () =>
                      import("src/layouts/BaseLayout.vue").catch(
                        showLoadErrorPage
                      ),
                    props: true,
                    beforeEnter(to, from, next) {
                      if (
                        useTeamRoute(to).isRouteRestricted(
                          useStreamsStore().getStreamBySlug(
                            to.params.streamSlug
                          )?.organisation_team_id
                        )
                      ) {
                        next(useProductMenu(true).socialRoute.value);
                      } else {
                        next();
                      }
                    },
                    meta: {
                      title: (route) => {
                        const stream = useStreamsStore().getStreamBySlug(
                          route.params.streamSlug
                        );

                        if (!stream) {
                          return null;
                        }

                        return stream.label;
                      },
                    },
                    children: [
                      {
                        path: "report/:selectedStreamId",
                        name: "social-bookmark-report",
                        component: () => import("src/components/SocialReport"),
                        props: true,
                        meta: {
                          title: () =>
                            getLocaleText("routes.bookmarks_report_create"),
                        },
                      },
                      {
                        name: "socialBookmarkStream",
                        path: "",
                        component: () =>
                          import("src/layouts/StreamsLayout.vue").catch(
                            showLoadErrorPage
                          ),
                        props: (route) => ({
                          bookmarks: true,
                          social: true,
                          ...route.params,
                          shared: route.params.groupSlug === "shared",
                        }),
                        meta: {
                          reuseComponent: true,
                        },
                      },
                      {
                        path: "edit",
                        component: () =>
                          import("src/layouts/BaseLayout.vue").catch(
                            showLoadErrorPage
                          ),
                        props: true,
                        children: [
                          {
                            path: ":tab?",
                            component: () =>
                              import("src/layouts/BaseLayout.vue").catch(
                                showLoadErrorPage
                              ),
                            meta: {
                              title: () =>
                                getLocaleText("routes.tagged_folder_edit"),
                            },
                            props: true,
                            children: [
                              {
                                path: "",
                                name: "social-bookmark-stream-edit",
                                component: () =>
                                  import(
                                    "src/pages/BookmarkStreamEdit.vue"
                                  ).catch(showLoadErrorPage),
                                props: (route) => ({
                                  bookmarks: true,
                                  social: true,
                                  ...route.params,
                                  isSocialStream: true,
                                }),
                                meta: {
                                  reuseComponent: true,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "crm",
        name: "crm",
        component: () =>
          import("src/pages/Crm/Crm.vue").catch(showLoadErrorPage),
        meta: {
          features: {
            has: "has_journalist_enquiries",
          },
        },
        children: [
          {
            path: "/crm/login",
            name: "crm-login",
            component: () =>
              import("src/pages/Crm/CrmLogin.vue").catch(showLoadErrorPage),
            meta: {
              features: {
                has: "has_journalist_enquiries",
              },
              hideSearchBar: true,
              title: () => getLocaleText("routes.login"),
            },
          },
          {
            name: "crm-activities",
            path: "/crm/activities",
            component: () =>
              import("src/pages/Crm/CrmContent.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.crm_activities"),
              contentTitle: "Activities",
              icon: "img:https://assets.streem.com.au/icons/enquiry-events.svg",
              features: {
                has: "has_journalist_enquiries",
              },
            },
          },
          {
            name: "crm-open-enquiries",
            path: "/crm/open-enquiries",
            component: () =>
              import("src/pages/Crm/CrmContent.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.crm_open_enquiries"),
              contentTitle: "Open Enquiries",
              icon: "img:https://assets.streem.com.au/icons/open-enquiries.svg",
              features: {
                has: "has_journalist_enquiries",
              },
            },
          },
          {
            name: "crm-closed-enquiries",
            path: "/crm/closed-enquiries",
            component: () =>
              import("src/pages/Crm/CrmContent.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.crm_closed_enquiries"),
              contentTitle: "Closed Enquiries",
              icon: "img:https://assets.streem.com.au/icons/closed-enquiries.svg",
              features: {
                has: "has_journalist_enquiries",
              },
            },
          },
        ],
      },
      {
        name: "csl",
        path: "csl",
        component: () => import("src/pages/CSL.vue").catch(showLoadErrorPage),
        meta: {
          hideMenu: true,
          hideSearchBar: true,
          hideHeader: true,
          title: () => getLocaleText("routes.csl"),
        },
      },
      {
        path: "streams",
        component: () =>
          import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
        props: true,
        children: [
          {
            path: "new",
            name: "mention-stream-new",
            component: () =>
              import("src/pages/MentionStreamBuilderPage.vue").catch(
                showLoadErrorPage
              ),
            props: true,
            meta: {
              title: () => getLocaleText("routes.mention_stream_create"),
            },
          },
          {
            path: ":groupSlug?",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            beforeEnter(to, from, next) {
              let organisationTeamId;

              if (to.params.groupSlug === "launchpad") {
                organisationTeamId = useStreamsStore().getStreamBySlug(
                  to.params.streamSlug
                )?.group?.organisation_team_id;
              } else {
                organisationTeamId =
                  useStreamGroupsStore().getStreamGroupBySlug(
                    to.params.groupSlug
                  )?.organisation_team_id;
              }

              if (useTeamRoute(to).isRouteRestricted(organisationTeamId)) {
                next("/");
              } else {
                next();
              }
            },
            meta: {
              title: (route) => {
                if (route.params.groupSlug === "shared")
                  return getLocaleText("routes.shared_streams");
                if (route.params.groupSlug === "launchpad")
                  return getLocaleText("routes.launchpad");

                const streamGroup = useStreamGroupsStore().getStreamGroupBySlug(
                  route.params.groupSlug
                );

                return streamGroup?.label;
              },
              route: (path, route) => {
                if (route.params.groupSlug === "launchpad") {
                  return "/";
                }

                return path;
              },
            },
            children: [
              {
                path: "new",
                name: "mention-group-stream-new",
                component: () =>
                  import("src/pages/MentionStreamBuilderPage.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
                meta: {
                  title: () => getLocaleText("routes.mention_stream_create"),
                },
              },
              {
                path: "edit",
                name: "mention-stream-group-edit",
                component: () =>
                  import(
                    "src/pages/MentionStream/MentionStreamGroupEdit.vue"
                  ).catch(showLoadErrorPage),
                props: true,
                meta: {
                  parent: "stream",
                  title: () => getLocaleText("routes.stream_group_edit"),
                },
              },
              {
                path: ":streamSlug?",
                component: () =>
                  import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
                meta: {
                  title: (route) => {
                    const stream = useStreamsStore().getStreamBySlug(
                      route.params.streamSlug
                    );

                    if (!stream) {
                      return null;
                    }

                    return stream.label;
                  },
                  route: (path, route) => {
                    if (route.params.groupSlug === "launchpad") {
                      return "/";
                    }

                    return path;
                  },
                },
                children: [
                  {
                    path: "report/:selectedStreamId",
                    name: "mention-stream-report-new",
                    component: () =>
                      import("src/pages/MentionStreamReport.vue").catch(
                        showLoadErrorPage
                      ),
                    props: true,
                    meta: {
                      title: () =>
                        getLocaleText("routes.mention_stream_report_create"),
                    },
                  },
                  {
                    path: "external-item",
                    component: () =>
                      import("src/layouts/BaseLayout.vue").catch(
                        showLoadErrorPage
                      ),
                    props: true,
                    children: [
                      {
                        path: "new/:streamId",
                        name: "external-item-new",
                        component: () =>
                          import("shared/pages/ExternalItemEdit.vue").catch(
                            showLoadErrorPage
                          ),
                        props: true,
                        meta: {
                          title: () =>
                            getLocaleText("routes.external_item_create"),
                        },
                      },
                      {
                        path: "edit/:externalItemId",
                        name: "external-items-edit",
                        component: () =>
                          import("shared/pages/ExternalItemEdit.vue").catch(
                            showLoadErrorPage
                          ),
                        props: true,
                        meta: {
                          title: () =>
                            getLocaleText("routes.external_item_edit"),
                        },
                      },
                    ],
                  },
                  {
                    path: "edit",
                    component: () =>
                      import("src/layouts/BaseLayout.vue").catch(
                        showLoadErrorPage
                      ),
                    props: true,
                    children: [
                      {
                        path: ":tab?",
                        component: () =>
                          import("src/layouts/BaseLayout.vue").catch(
                            showLoadErrorPage
                          ),
                        meta: {
                          title: () =>
                            getLocaleText("routes.mention_stream_edit"),
                        },
                        props: true,
                        children: [
                          {
                            path: "notification/:notificationId?",
                            name: "mention-stream-notification-edit",
                            component: () =>
                              import(
                                "src/pages/MentionStream/MentionStreamNotificationEdit.vue"
                              ).catch(showLoadErrorPage),
                            props: true,
                            meta: {
                              title: (route) =>
                                route.params.notificationId
                                  ? getLocaleText("routes.notification_edit")
                                  : getLocaleText("routes.notification_create"),
                            },
                          },
                          {
                            name: "mention-stream-edit",
                            path: "",
                            component: () =>
                              import(
                                "src/pages/MentionStream/MentionStreamEdit.vue"
                              ).catch(showLoadErrorPage),
                            props: true,
                            meta: {
                              reuseComponent: true,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "stream",
                    path: "",
                    component: () =>
                      import("src/layouts/StreamsLayout.vue").catch(
                        showLoadErrorPage
                      ),
                    props: (route) => ({
                      ...route.params,
                      shared: route.params.groupSlug === "shared",
                    }),
                    meta: {
                      reuseComponent: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "tagged",
        component: () =>
          import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
        meta: {
          title: (route) => {
            if (route.params.launchpad) {
              return getLocaleText("routes.launchpad");
            }

            return getLocaleText("routes.tagged_folders");
          },
          route: (path, route) => {
            if (route.params.launchpad) {
              return "/";
            }

            if (route.params.groupSlug) {
              if (route.params.groupSlug === "shared") {
                return `${path}/my-tagged`;
              }

              return `${path}/${route.params.groupSlug}`;
            }

            return path;
          },
        },
        children: [
          {
            path: ":groupSlug?",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            meta: {
              title: (route) => {
                if (route.params.groupSlug === "shared")
                  return getLocaleText("routes.shared");
                if (route.params.groupSlug === "launchpad")
                  return getLocaleText("routes.launchpad");

                const streamGroup = useStreamGroupsStore().getStreamGroupBySlug(
                  route.params.groupSlug
                );

                return streamGroup?.label;
              },
              route: (path, route) => {
                if (route.params.groupSlug === "launchpad") {
                  return "/";
                }

                return path;
              },
            },
            children: [
              {
                path: ":streamSlug?",
                component: () =>
                  import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
                props: true,
                meta: {
                  title: (route) => {
                    const stream = useStreamsStore().getStreamBySlug(
                      route.params.streamSlug
                    );

                    if (!stream) {
                      return null;
                    }

                    return stream.label;
                  },
                  route: (path, route) => {
                    if (route.params.groupSlug === "launchpad") {
                      return "/";
                    }

                    return path;
                  },
                },
                beforeEnter(to, from, next) {
                  if (
                    useTeamRoute(to).isRouteRestricted(
                      useStreamsStore().getStreamBySlug(to.params.streamSlug)
                        ?.organisation_team_id
                    )
                  ) {
                    next("/");
                  } else {
                    next();
                  }
                },
                children: [
                  {
                    path: "external-item/:streamId",
                    name: "bookmark-external-item-new",
                    component: () =>
                      import("shared/pages/ExternalItemEdit.vue").catch(
                        showLoadErrorPage
                      ),
                    props: true,
                    meta: {
                      title: () => getLocaleText("routes.external_item_create"),
                    },
                  },
                  {
                    path: "report/:selectedStreamId",
                    name: "bookmark-report",
                    component: () =>
                      import("src/pages/MentionStreamReport.vue"),
                    props: true,
                    meta: {
                      title: () =>
                        getLocaleText("routes.mention_stream_report_create"),
                    },
                  },
                  {
                    name: "bookmarkStream",
                    path: "",
                    component: () =>
                      import("src/layouts/StreamsLayout.vue").catch(
                        showLoadErrorPage
                      ),
                    props: (route) => ({
                      bookmarks: true,
                      ...route.params,
                      shared: route.params.groupSlug === "shared",
                    }),
                    meta: {
                      reuseComponent: true,
                    },
                  },
                  {
                    path: "edit",
                    component: () =>
                      import("src/layouts/BaseLayout.vue").catch(
                        showLoadErrorPage
                      ),
                    props: true,
                    children: [
                      {
                        path: ":tab?",
                        component: () =>
                          import("src/layouts/BaseLayout.vue").catch(
                            showLoadErrorPage
                          ),
                        meta: {
                          title: () =>
                            getLocaleText("routes.tagged_folder_edit"),
                        },
                        props: true,
                        children: [
                          {
                            path: "",
                            name: "bookmark-stream-edit",
                            component: () =>
                              import("src/pages/BookmarkStreamEdit.vue").catch(
                                showLoadErrorPage
                              ),
                            props: true,
                            meta: {
                              reuseComponent: true,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "stream-notifications",
        component: () =>
          import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
        meta: {
          title: () => getLocaleText("routes.notifications"),
        },
        children: [
          { path: "", redirect: { name: "stream-notifications" } },
          {
            path: "/stream-notifications",
            name: "stream-notifications",
            component: () =>
              import("src/pages/StreamNotifications.vue").catch(
                showLoadErrorPage
              ),
          },
          {
            path: "notification/:notificationId?",
            name: "stream-notifications-edit",
            component: () =>
              import("shared/pages/StreamNotificationsEdit.vue").catch(
                showLoadErrorPage
              ),
            props: (route) => typecastRouteProps(route),
            meta: {
              title: (route) =>
                route.params.notificationId
                  ? getLocaleText("routes.notification_edit")
                  : getLocaleText("routes.notification_create"),
            },
          },
        ],
      },
      {
        path: "/view/:prefixMentionId",
        name: "mentionDetailView",
        component: () =>
          import("src/pages/MainPage.vue").catch(showLoadErrorPage),
        props: (route) => ({
          ...route.params,
          ...route.query,
          keywords: [].concat(
            route.query.keywords || route.query["keywords[]"] || []
          ),
        }),
      },
      {
        name: "account",
        path: ":product/account",
        redirect: "profile",
        component: () => import("src/pages/Account.vue"),
        meta: {
          title: () => getLocaleText("routes.account"),
          theme: "product",
        },
        children: [
          {
            path: "profile",
            name: "profile",
            component: () =>
              import("src/pages/AccountFormPage.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.profile"),
            },
          },
          {
            path: "preference",
            name: "preference",
            component: () =>
              import("shared/components/user/UserPreferencesForm.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.preferences"),
            },
          },
          {
            path: "security",
            name: "security",
            component: () =>
              import("src/pages/SecurityFormPage.vue").catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.security"),
            },
          },
        ],
      },
      {
        name: "organisation",
        path: ":product/organisation",
        component: () => import("src/pages/Organisation.vue"),
        meta: {
          title: () => getLocaleText("routes.organisation"),
          theme: "product",
        },
        children: [
          {
            path: "usage",
            name: "usage",
            component: () =>
              import("shared/components/user/UsageOverview.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.usage"),
            },
          },
          {
            path: "ratecard",
            name: "ratecard",
            component: () =>
              import("shared/components/user/RateCardOverview.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.rate_card"),
            },
          },
          {
            path: "brand-profiles/:type",
            component: () =>
              import("src/layouts/BaseLayout.vue").catch(showLoadErrorPage),
            props: true,
            meta: {
              title: () => getLocaleText("routes.brand_profiles"),
            },
            children: [
              {
                path: "new",
                name: "brand-profiles-new",
                component: () =>
                  import("src/pages/BrandProfileEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
                meta: {
                  title: () => getLocaleText("routes.brand_profile_create"),
                },
                beforeEnter(to, from, next) {
                  return organisationFeatures.canManageBrandProfiles()
                    ? next()
                    : next({ name: outreachFeatures.defaultRouteName(to) });
                },
              },
              {
                path: ":organisationBrandId/edit",
                name: "brand-profiles-edit",
                component: () =>
                  import("src/pages/BrandProfileEdit.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
                meta: {
                  title: () => getLocaleText("routes.brand_profile_edit"),
                },
                beforeEnter(to, from, next) {
                  return organisationFeatures.canManageBrandProfiles()
                    ? next()
                    : next({ name: outreachFeatures.defaultRouteName(to) });
                },
              },
              {
                path: "",
                name: "brand-profiles",
                component: () =>
                  import("shared/components/user/BrandProfiles.vue").catch(
                    showLoadErrorPage
                  ),
                props: true,
              },
            ],
          },
          {
            path: "users",
            name: "users",
            component: () =>
              import("shared/components/user/UserManagement.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.users"),
            },
          },
          {
            path: "teams",
            name: "teams",
            component: () =>
              import("shared/components/user/TeamsManagement.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.teams"),
            },
          },
          {
            path: "mention-streams",
            name: "mention-streams",
            component: () =>
              import(
                "shared/components/user/MentionStreamsManagement.vue"
              ).catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.mention_streams"),
            },
          },
          {
            path: "social-streams",
            name: "social-streams",
            component: () =>
              import(
                "shared/components/user/MentionStreamsManagement.vue"
              ).catch(showLoadErrorPage),
            meta: {
              title: () => getLocaleText("routes.social_streams"),
            },
          },
          {
            path: "clients",
            name: "clients",
            component: () =>
              import("shared/components/user/ClientManagement.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.clients"),
              canAccess() {
                return useUserStore().organisation.pr_agency;
              },
            },
          },
          {
            path: "team",
            name: "team",
            component: () =>
              import("shared/components/user/TeamOverview.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.team"),
            },
          },
          {
            path: "distribution-list",
            name: "distribution-list",
            component: () =>
              import("shared/components/user/DistributionLists.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.distribution_lists"),
            },
          },
          {
            path: "integrations",
            name: "integrations",
            component: () =>
              import("shared/components/user/Integrations.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.integrations"),
            },
          },
          {
            path: "metric-editor",
            name: "metric-editor",
            component: () =>
              import("shared/components/user/MetricEditor.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.metric_editor"),
            },
          },
          {
            path: "domains",
            name: "domains",
            component: () =>
              import("shared/components/user/DomainManagement.vue").catch(
                showLoadErrorPage
              ),
            meta: {
              title: () => getLocaleText("routes.domains"),
              features: {
                has: "has_domain_management",
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/organisation-report-specifications/:specId",
    name: "organisation-report-specifications",
    component: () =>
      import("src/pages/OrganisationReportSpecificationsPopup.vue").catch(
        showLoadErrorPage
      ),
    props: true,
    meta: {
      permissions: {
        has: "can_manage_organisation_reports",
      },
    },
  },
  {
    path: "/preview-report/:reportId?",
    name: "preview-curated-email-report",
    component: () =>
      import("src/pages/OrganisationReportPreviewPage.vue").catch(
        showLoadErrorPage
      ),
    props: true,
    meta: {
      permissions: {
        has: "can_manage_organisation_reports",
      },
    },
  },
  {
    path: "/mobile",
    name: "mobile-trap",
    component: () =>
      import("src/pages/MobileTrap.vue").catch(showLoadErrorPage),
    props: true,
    meta: {
      public: true,
    },
  },
  {
    // Always leave this as last one
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
]);
