import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ArticleEdition from "shared/resources/ArticleEdition";
import Website from "shared/resources/Website";
import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class Article extends ApplicationRecord {
  static jsonapiType = "articles";

  @BelongsTo() source: Website;

  @BelongsTo() currentEdition: ArticleEdition;

  @HasMany() editions: ArticleEdition[];

  @Attr() authorName: string;

  @Attr() body: string;

  @Attr() cisionSource: string;

  @Attr() ucpId: string;

  @Attr() internationalPlus: boolean;

  @Attr() live: boolean;

  @Attr() ogDescription: string;

  @Attr() ogImage: string;

  @Attr() publishedAt: string;

  @Attr() removed: boolean;

  @Attr() title: string;

  @Attr() translatedBody: string;

  @Attr() translatedTitle: string;

  @Attr() url: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) plainTextUrl: string;

  @Attr({ persist: false }) publicLink: string;

  @Attr({ persist: false }) type: string;

  updateEditions(): void {
    const editionAttributeChanged = Object.keys(this.changes()).some((key) =>
      ["title", "body", "publishedAt"].includes(key)
    );

    if (editionAttributeChanged) {
      this.editions.push(
        new ArticleEdition({
          title: this.title,
          body: this.body,
          publishedAt: this.publishedAt,
        })
      );
    }
  }
}

export default Article;
