<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="full-width">
    <!--Card for list view-->
    <template v-if="isListView">
      <div
        class="card column full-height"
        @click="handleClick"
      >
        <div
          :class="!hideBorderColor && `border-color-${medium}`"
          class="mention-content relative list-content row no-wrap items-center full-height"
        >
          <QCheckbox
            v-show="showCheckbox"
            :model-value="selected"
            size="27px"
            class="col-auto q-pb-md"
            @update:model-value="$emit('mention-selected', mention)"
          />
          <div class="column col q-ml-md">
            <slot name="list-title">
              <div class="row q-mt-sm full-height flex">
                <span class="flex-1">
                  <span class="bold">{{ listTitle || title }}</span>
                  <QIcon
                    v-if="isSocial && mention.author_verified"
                    name="ion-checkmark-circle"
                    color="black"
                    class="q-ma-xs q-mb-sm"
                    style="height: 0 !important"
                    size="13px"
                  />
                </span>
                <span class="row items-center">
                  <slot name="list-actions" />
                  <Component
                    :is="actionSheetComponent"
                    :stream="stream"
                    :mention="mention"
                    :mentions="mentions"
                    :origin="origin"
                    :product="product"
                    class="mention-dropdown"
                    @mention-removed="removeMention"
                    @tags-changed-in-action-sheet="updateTags"
                  />
                </span>
              </div>
              <p class="q-pl-xs q-pt-xs softer">
                {{
                  formatIntlDate(mention.timestamp * 1000, {
                    day: "numeric",
                    month: "short",
                    hour: "numeric",
                    minute: "numeric",
                  })
                }}
              </p>
            </slot>
            <slot name="list-body">
              <MentionExcerpts
                :mention="mention"
                :no-highlighting="noHighlighting"
                :always-show-keywords="alwaysShowKeywords"
                list-view
              />
            </slot>

            <BookmarkLabels
              :bookmarks="bookmarkTags"
              :loading="mention.loadingTag"
              @tag-deleted="deleteTag"
            />
            <slot name="list-body-append" />

            <MentionRestriction
              v-if="isMentionRestricted"
              class="tw-mt-2"
            />
          </div>
        </div>
      </div>
    </template>

    <!--Card for streams-->
    <template v-else>
      <div
        class="card mention-stream-view-card column"
        :class="[
          hideBorderTop && 'hide-radius-top',
          hideBorderBottom && 'hide-radius-bottom',
        ]"
        @click="handleClick"
      >
        <slot
          name="before"
          :hide-border-top="hideBorderTop"
        />
        <div
          :class="[
            !hideBorderColor && `border-color-${medium}`,
            (hideBorderTop || hideMediaBorder) && 'hide-radius-top',
            hideBorderBottom && 'hide-radius-bottom',
            (translatedMention.error || translatedMention.success) &&
              'hide-padding-bottom',
          ]"
          class="mention-content relative stream-content column no-wrap"
        >
          <div class="row no-wrap">
            <QCheckbox
              v-show="showCheckbox"
              v-model="mention.selected"
              size="xs"
              class="items-baseline q-pr-xs"
              @update:model-value="$emit('mention-selected', mention)"
            />
            <div class="full-width">
              <div class="row no-wrap justify-between">
                <div class="column">
                  <template v-if="!translatedMention.loading">
                    <div
                      v-if="
                        canShowReactScore &&
                        averageScore > HIGH_REACT_SCORE_THRESHOLD
                      "
                      class="high-risk-label"
                    >
                      {{ $t("mention_card.high_risk") }}
                    </div>
                    <strong
                      v-if="cardTitle"
                      class="q-mb-sm block bigger2 e2e-mention-title"
                    >
                      <slot
                        name="innerTitle"
                        :title="cardTitle"
                      >
                        {{ cardTitle }}
                      </slot>
                    </strong>

                    <div class="row no-wrap justify-start items-start q-mb-md">
                      <!--On mobile, we display logo with the content to save space-->
                      <img
                        v-if="logo"
                        :src="logo"
                        class="logo"
                        :alt="$t('mention_card.source_logo')"
                      />
                      <QIcon
                        v-else-if="icon"
                        :name="icon"
                        size="40px"
                        class="logo"
                      />
                      <div
                        class="column col e2e-mention-details flex-basis-100"
                      >
                        <slot name="header" />
                      </div>
                    </div>
                  </template>
                </div>

                <div class="actions column items-center">
                  <Component
                    :is="actionSheetComponent"
                    v-show="!translatedMention.loading"
                    :mention="mention"
                    :mentions="mentions"
                    :stream="stream"
                    :origin="origin"
                    :product="product"
                    :is-mention-translated="translatedMention.success"
                    button-color="blue-grey"
                    class="mention-dropdown"
                    @mention-removed="removeMention"
                    @tags-changed-in-action-sheet="updateTags"
                    @translate="handleTranslation"
                  />
                </div>
              </div>

              <TranslationLoader v-if="translatedMention.loading" />

              <template v-else>
                <slot name="beforeBody" />
                <slot
                  name="body"
                  :mention-formatted="mentionFormatted"
                >
                  <MentionExcerpts
                    v-if="displayMentionExcerpts"
                    :mention="mentionFormatted"
                    :no-highlighting="noHighlighting"
                    :always-show-keywords="alwaysShowKeywords"
                    expandable
                  />
                </slot>

                <slot
                  v-if="!options.hideAfterBodySlot"
                  name="afterBody"
                />

                <slot />
                <BookmarkLabels
                  v-if="bookmarkTags.length"
                  :bookmarks="bookmarkTags"
                  @tag-deleted="deleteTag"
                />

                <div
                  v-if="translatedMention.error || translatedMention.success"
                  class="translation-footer"
                  @click.stop
                >
                  <div class="translation-footer-content">
                    <TranslationDescription
                      v-if="
                        translatedMention.success &&
                        translatedMention.source_language
                      "
                      :from-language="translatedMention.source_language"
                    />
                    <TranslationError
                      v-else
                      @retry="handleTranslation"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <slot name="afterRow" />

          <MentionRestriction
            v-if="isMentionRestricted"
            class="tw-mt-2"
          />
        </div>
      </div>

      <slot
        v-if="!options.hideAfterSlot"
        name="after"
      />
    </template>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { Notify } from "quasar";

import BookmarkLabels from "shared/components/BookmarkLabels.vue";
import MentionExcerpts from "shared/components/MentionExcerpts.vue";
import MentionRestriction from "shared/components/MentionRestriction.vue";
import UniversalPlayerControlWidget from "shared/components/players/UniversalPlayerControlWidget.vue";
import TranslationDescription from "shared/components/translation/TranslationDescription.vue";
import TranslationError from "shared/components/translation/TranslationError.vue";
import TranslationLoader from "shared/components/translation/TranslationLoader.vue";
import useTeamPicker from "shared/composables/useTeamPicker";
import { useTranslation } from "shared/composables/useTranslation";
import { HIGH_REACT_SCORE_THRESHOLD } from "shared/constants";
import { formatIntlDate } from "shared/helpers/date";
import { mentionKeywords } from "shared/helpers/mentions";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useUniversalSearchStore } from "shared/stores/universalSearch";

import { props } from "./MentionCardProps";

export default {
  name: "MentionCard",
  components: {
    MentionRestriction,
    MentionExcerpts,
    BookmarkLabels,
    UniversalPlayerControlWidget,
    TranslationLoader,
    TranslationError,
    TranslationDescription,
  },
  props,
  emits: ["mention-selected", "click", "mention-removed"],
  setup(context) {
    const mentionCountsStore = useMentionCountsStore();
    const { mentionCount } = storeToRefs(mentionCountsStore);

    const universalSearchStore = useUniversalSearchStore();
    const { searchOpen } = storeToRefs(universalSearchStore);

    const { translateMention, translatedMention } = useTranslation(
      context.mention
    );

    const { selectedTeamPreference } = useTeamPicker();

    return {
      mentionCount,
      searchOpen,

      translateMention,
      translatedMention,
      selectedTeamPreference,
    };
  },
  data() {
    return {
      HIGH_REACT_SCORE_THRESHOLD,
      bookmarkTags: [],
    };
  },
  computed: {
    isMentionRestricted() {
      return this.mention.outside_source_retention_period;
    },
    canShowReactScore() {
      return this.$features.has("has_react_score");
    },
    reactScores() {
      return this.mention.factmata_enrichment || {};
    },
    averageScore() {
      return Math.round(this.reactScores.risk_score * 100);
    },
    isSocial() {
      return [
        "tweet",
        "facebook_post",
        "youtube_video",
        "instagram_post",
        "reddit_post",
        "reddit_post_comment",
      ].includes(this.mention.type);
    },
    mentionType() {
      if (this.mention.type === "transcript_request") {
        return this.mention.source.type;
      }

      return this.mention.type;
    },
    medium() {
      return this.mention.medium?.toLowerCase();
    },
    hideMediaBorder() {
      return ["tv_caption", "tv_super", "youtube_video"].includes(
        this.mention.type
      );
    },
    hideBorderTop() {
      return (
        !this.options.noSquareBorders &&
        !this.options.noSquareTopBorder &&
        this.mentions[0] === this.mention &&
        Boolean(this.mentionCount(this.stream.id))
      );
    },
    hideBorderBottom() {
      return (
        !this.options.noSquareBorders &&
        (this.canShowRelatedArticle ||
          (this.mention.similarMentions && this.mention.similarMentions.length))
      );
    },
    canShowRelatedArticle() {
      if (this.mention.related_article) {
        if (this.mention.type === "paper_article") {
          return this.$features.has("read_online_article");
        }

        return this.$features.has("view_paper_articles");
      }

      return false;
    },
    isListView() {
      return this.options && this.options.list;
    },
    displayMentionExcerpts() {
      return ![
        "journalist",
        "tv_media_request",
        "radio_media_request",
        "transcript_request",
        "TvChannel",
        "RadioStation",
      ].includes(this.mentionType);
    },
    origin() {
      const originKeyword = mentionKeywords(this.mention)[0] || "";

      if (this.searchOpen) {
        return {
          origin_type: "Search",
          origin_keyword: originKeyword,
        };
      }

      if (this.stream.id) {
        return {
          origin_id: this.stream.id,
          origin_type: "Stream",
          origin_keyword: originKeyword,
        };
      }

      if (this.stream.folderId) {
        return {
          origin_id: this.stream.folderId,
          origin_type: "Folder",
          origin_keyword: originKeyword,
        };
      }

      return {};
    },
    cardTitle() {
      if (this.translatedMention.success) {
        return this.translatedMention.title;
      }

      return this.title;
    },
    mentionFormatted() {
      if (this.translatedMention.success) {
        const { title, excerpts } = this.translatedMention;

        return {
          ...this.mention,
          title,
          excerpts,
        };
      }

      return this.mention;
    },
  },
  watch: {
    "mention.bookmarks": {
      handler: "resetBookmarks",
      deep: true,
    },
  },
  mounted() {
    this.resetBookmarks();
  },
  methods: {
    formatIntlDate,
    resetBookmarks() {
      if (this.$features.has("has_team_workspaces")) {
        this.bookmarkTags = (this.mention.bookmarks || []).filter(
          (bookmark) =>
            bookmark.organisation_team_id === this.selectedTeamPreference ||
            !bookmark.organisation_team_id
        );
      } else {
        this.bookmarkTags = this.mention.bookmarks || [];
      }
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    updateTags(bookmarks) {
      this.bookmarkTags = bookmarks;
    },
    deleteTag(tagId) {
      this.bookmarkTags = this.bookmarkTags.filter((tag) => tag.id !== tagId);
    },
    handleClick() {
      this.$emit("click", this.mention);
    },
    async handleTranslation() {
      if (this.translatedMention.loading || this.translatedMention.success) {
        return;
      }

      await this.translateMention(this.mention);

      if (!this.translatedMention.success) {
        return;
      }

      Notify.create({
        message: this.$t("mention_card.mention_translated"),
        actions: [
          {
            icon: "close",
            color: "white",
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.high-risk-label {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $react-score-warning;
  color: white;
  width: 70px;
  height: 20px;
  border-radius: 19px;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  margin-top: -8px;

  :deep(.q-btn__wrapper) {
    min-width: 0 !important;
    min-height: 0 !important;
  }

  :deep(.play-button) {
    height: auto;
  }
}

.q-checkbox {
  :deep(.q-checkbox__bg) {
    border: 1px solid #3e475e;
  }
}

.e2e-mention-title {
  word-break: break-word;
  letter-spacing: 0.02em;
}

:deep(.muted) {
  font-size: 80%;
  line-height: 100%;
  opacity: 0.5;
  color: #444;
  font-weight: normal;
}

:deep(.pull-right) {
  float: right;
}

:deep(.publishers-list) {
  background-color: #eee;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;

  > a,
  > span {
    width: 100%;
    display: inline-block;
  }
}

:deep(.mention-content) {
  border-left: 5px solid transparent;
  width: 100%;

  &.stream-content {
    border-radius: 4px;
    padding: 15px 10px 20px 15px;
  }

  &.list-content {
    padding: 0 10px 10px;
  }
}

:deep(.syndication-time) {
  margin-top: 5px;
  margin-left: auto;
  margin-right: 5px;
  white-space: nowrap;
  padding-left: 5px;
}

:deep(.syndication-matches-stream) {
  display: inline-block;
  width: 0.8em;
}

:deep(.card) {
  &.syndication-card {
    margin-top: 2px;
  }

  p {
    margin-bottom: 5px;
    line-height: 20px;
  }

  p em {
    background-color: rgb(252 220 89 / 30%);
    padding: 1px 2px;
    font-style: normal;
  }

  .logo {
    width: 40px;
    margin-right: 10px;
    color: #767676;
  }

  .thumbnail {
    width: 50%;
    object-fit: cover;
    object-position: 50% 30%;
    height: 80px;
    border-bottom: 1px solid $ds-denim-3;

    &:nth-child(1) {
      border-top-left-radius: 4px;
    }

    &:nth-child(2) {
      border-top-right-radius: 4px;
    }
  }

  &.warning {
    h3 {
      margin: 12px 0;
    }

    .q-icon {
      vertical-align: -3px;
    }
  }

  .ion-checkmark-circle {
    color: rgb(73 208 103 / 50%);
  }

  .ion-close-circle {
    color: rgb(249 88 88 / 50%);
  }

  .ion-remove-circle {
    color: rgb(50 159 236 / 50%);
  }
}

:deep(.q-item-label) {
  color: yellow;
}

.youtube {
  :deep(.logo.q-icon) {
    height: auto;
  }
}

.translation-footer {
  margin-top: 16px;
  margin-left: -15px;
  margin-right: -15px;
  border-top: 1px solid;
  border-color: var(--s-color-denim-3);
  cursor: text;

  &-content {
    padding: 16px;
  }
}

.hide-padding-bottom {
  padding-bottom: 0 !important;
}
</style>
