<template>
  <div
    ref="root"
    class="c-base-button-dropdown"
  >
    <slot
      name="btn"
      :toggle="toggle"
      :listeners="buttonListeners"
    >
      <BaseButton
        v-bind="buttonAttributes"
        :disabled="disabled"
        :label="label"
        :new-design="newDesign"
        :new-icon="newIcon"
        :icon="newIcon ? icon : ''"
        :menu-item="menuItem"
        :class="{ 'tw-w-full': menuItem }"
        v-on="buttonListeners"
      >
        <QIcon
          v-if="!newIcon && icon"
          :name="icon"
          :size="iconSize"
        />

        <span
          v-if="label"
          :class="{
            'tw-flex-1': menuItem,
            'tw-text-left': menuItem,
            'tw-pl-1': icon && !newDesign,
            'tw-pr-1': chevron && !newDesign,
          }"
        >
          {{ label }}
        </span>

        <QIcon
          v-if="chevron"
          name="fas fa-chevron-down"
          :size="chevronSize"
          :class="{ rotate: visibleModel }"
          class="chevron"
        />

        <slot name="tooltip" />
      </BaseButton>
    </slot>

    <Popper
      :key="uid"
      v-model="visibleModel"
      :placement="placement"
      :offset="dropdownOffset"
      disable-click-outside
      :same-width="sameWidthPopper"
      class="c-base-button-dropdown__body"
      :class="dropdownClass"
      :flip="flip"
      :fallback-placements="fallbackPlacements"
      :tether="tether"
      :boundary="boundary"
      v-on="bodyListeners"
    >
      <slot />
    </Popper>
  </div>
</template>

<script setup lang="ts">
import { omit } from "lodash-es";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  useAttrs,
  watch,
} from "vue";

import BaseButton from "shared/components/base/BaseButton.vue";
import Popper, { type PopperProps } from "shared/components/base/Popper.vue";
import generateId from "shared/helpers/generateId";

type Interaction = "click" | "hover";

type DocumentEventName = Pick<
  HTMLBodyElementEventMap,
  "click" | "mouseleave" | "mouseenter"
>;

type DocumentEventListeners = {
  [K in keyof DocumentEventName]?: (event: MouseEvent) => void;
};

interface Dropdown {
  $el: HTMLDivElement;
  hide: () => boolean;
}

interface Props
  extends Pick<
    PopperProps,
    | "boundary"
    | "fallbackPlacements"
    | "flip"
    | "offset"
    | "placement"
    | "tether"
  > {
  chevron?: boolean;
  closeOnClick?: boolean;
  disabled?: boolean;
  icon?: string;
  iconSize?: string;
  interaction?: Interaction;
  label?: string;
  newDesign?: boolean;
  newDropdown?: boolean;
  newIcon?: boolean;
  sameWidthPopper?: boolean;
  submenu?: boolean;
  menuItem?: boolean;
  disableClickOutside?: boolean;
}

const visible = defineModel<boolean>();

const props = withDefaults(defineProps<Props>(), {
  boundary: null,
  fallbackPlacements: () => ["top"],
  icon: "",
  iconSize: "xs",
  interaction: "click",
  label: "",
  offset: undefined,
  placement: "bottom",
});

const emit = defineEmits<{
  "click-outside": [];
  click: [boolean];
  hide: [];
  show: [];
}>();

const attrs = useAttrs();

const uid = ref(generateId());

const timeout = ref<ReturnType<typeof setTimeout>>();

let visibleDropdowns: Dropdown[] = [];
let mousedown = false;
const root = ref<HTMLDivElement>();

const dropdownClass = computed<string>(() =>
  props.newDropdown ? "c-dropdown-menu" : "menu-shadow-box rounded-borders"
);

const dropdownOffset = computed<Props["offset"]>(() => {
  if (props.offset) {
    return props.offset;
  }

  return props.newDropdown ? [0, 1] : [0, 0];
});

const chevronSize = computed<string>(() => (props.label ? "8px" : "12px"));

const visibleModel = computed<boolean>({
  get() {
    return Boolean(visible.value);
  },
  set(value) {
    visible.value = value;

    if (value) {
      emit("show");
    } else {
      emit("hide");
    }
  },
});

const documentBody = document.querySelector("body");

const documentEventName = computed<keyof DocumentEventName>(() =>
  props.interaction === "hover" ? "mouseleave" : "click"
);

function closeDropdowns(event: MouseEvent): void {
  if (mousedown) {
    mousedown = false;

    return;
  }

  if (!event.target) {
    return;
  }

  visibleDropdowns.forEach((dropdown, index) => {
    if (dropdown && !dropdown.$el.contains(event.target as Node)) {
      const hidden = dropdown.hide();
      if (!hidden) return;
      visibleDropdowns.splice(index, 1);
      emit("click-outside");
    }
  });
}

function addDocumentListener(): void {
  documentBody?.addEventListener(documentEventName.value, closeDropdowns, true);
}

function removeDocumentListener(): void {
  documentBody?.removeEventListener(
    documentEventName.value,
    closeDropdowns,
    true
  );
}

function hide(): boolean {
  if (props.disableClickOutside) {
    return false;
  }

  visibleModel.value = false;
  removeDocumentListener();
  visibleDropdowns = visibleDropdowns.filter(
    (dropdown) => dropdown.$el !== root.value
  );
  emit("hide");

  return true;
}

function show(): void {
  visibleModel.value = true;
  addDocumentListener();
  visibleDropdowns.push({ $el: root.value!, hide });
}

function toggle(): void {
  if (visibleModel.value) {
    hide();
  } else {
    show();
  }
}

function handleHideEvent({ relatedTarget }: MouseEvent): void {
  if (!relatedTarget) {
    return;
  }

  const target = relatedTarget as HTMLElement;

  if (
    target.closest(".c-base-button-dropdown") ||
    target.closest(".c-base-button-dropdown__body")
  ) {
    return;
  }

  hide();
}

function onButtonClick(event: MouseEvent): void {
  event.stopPropagation();

  if (props.interaction === "hover") {
    return;
  }

  toggle();
  emit("click", visibleModel.value);
}

function onButtonEnter(): void {
  show();
}

function onButtonLeave(event: MouseEvent): void {
  if (props.submenu) {
    timeout.value = setTimeout(hide, 150);
  }

  handleHideEvent(event);
}

function onBodyClick(): void {
  if (props.closeOnClick) {
    hide();
  }
}

function onBodyEnter(): void {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
}

function onBodyLeave(event: MouseEvent): void {
  handleHideEvent(event);
}

const buttonAttributes = computed<Record<string, unknown>>(() =>
  omit(attrs, ["class", "style"])
);

const buttonListeners = computed<DocumentEventListeners>(() => ({
  click: onButtonClick,
  ...(props.interaction === "hover" && {
    mouseenter: onButtonEnter,
    mouseleave: onButtonLeave,
  }),
}));

const bodyListeners = computed<DocumentEventListeners>(() => {
  if (props.interaction === "hover") {
    return {
      mouseenter: onBodyEnter,
      mouseleave: onBodyLeave,
    };
  }

  return { click: onBodyClick };
});

watch(
  visible,
  (isVisible) => {
    if (isVisible) {
      show();
    } else {
      hide();
    }
  },
  { immediate: true }
);

function onMouseDown(): void {
  mousedown = true;
}

function onMouseUp(): void {
  mousedown = false;
}

onBeforeUnmount(() => {
  removeDocumentListener();
  root.value?.removeEventListener("mousedown", onMouseDown);
  root.value?.removeEventListener("mouseup", onMouseUp);
});

onMounted(() => {
  root.value?.addEventListener("mousedown", onMouseDown);
  root.value?.addEventListener("mouseup", onMouseUp);
});
</script>

<style lang="scss" scoped>
.c-base-button-dropdown {
  &__body {
    z-index: 7500;
    overflow: visible;
    background: var(--s-card-bg);
  }

  .chevron {
    transition: transform 0.1s ease;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}
</style>
