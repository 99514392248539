import type { Chart } from "shared/resources/types";

import * as cisionConfig from "./cision.json";
import * as cisionCAConfig from "./cision_ca.json";
import * as cisionFRConfig from "./cision_fr.json";
import * as cisionUSConfig from "./cision_us.json";
import * as streemConfig from "./streem.json";
import { AppConfig, CisionSpecificConfig, Region } from "./types";

const CONFIG_BY_REGION: { [key in Region]?: CisionSpecificConfig } = {
  [Region.CA]: cisionCAConfig,
  [Region.FR]: cisionFRConfig,
  [Region.US]: cisionUSConfig,
};

function buildConfig(
  config: typeof cisionConfig | typeof streemConfig
): AppConfig {
  return {
    ...config,
    instantInsightsCharts: config.instantInsightsCharts as Chart[],
    socialAnalyseCharts: config.socialAnalyseCharts as Chart[],
  };
}

export default {
  getConfig(region: Region) {
    let config: AppConfig;

    if (region === Region.AU) {
      config = buildConfig(streemConfig);
    } else {
      config = buildConfig(cisionConfig);
    }

    const specificConfig = CONFIG_BY_REGION[region];

    if (specificConfig) {
      config = {
        ...config,
        ...specificConfig,
      };
    }

    return config;
  },
};
