<template>
  <span>
    {{ timeAgo }}
    <StreemTooltip>
      {{
        formatIntlDate(date, {
          dateStyle: "medium",
          timeStyle: "medium",
          timeZone: timezone,
        })
      }}
      {{ timezone }}
    </StreemTooltip>
  </span>
</template>

<script>
import isFuture from "date-fns/isFuture";

import {
  formatIntlDate,
  getTimezone,
  parseDate,
  timeAgo,
} from "shared/helpers/date";

export default {
  name: "TimeAgo",
  props: {
    date: {
      type: [Number, String, Date],
      required: true,
    },
    timezone: {
      type: String,
      default: () => getTimezone(),
    },
  },
  data() {
    return {
      interval: null,
      timeAgo: "",
    };
  },
  created() {
    this.setTimeAgo();
    this.interval = setInterval(this.setTimeAgo, 5000); // eslint-disable-line @typescript-eslint/no-implied-eval
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    formatIntlDate,
    setTimeAgo() {
      const date = this.date || new Date();

      if (isFuture(parseDate(date))) {
        this.timeAgo = formatIntlDate(date, { dateStyle: "medium" });
      } else {
        this.timeAgo = timeAgo(this.date || new Date());
      }
    },
  },
};
</script>
