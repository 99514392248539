<template>
  <div class="card tw-mt-0.5 tw-border-l-0 tw-border-r-0">
    <div
      class="tw-rounded-t-none tw-rounded-bl-[4px] tw-rounded-br-[4px] tw-border-0 tw-border-l-[5px] tw-border-solid tw-border-l-transparent tw-pl-2.5"
      :class="borderClass"
    >
      <div class="tw-flex tw-items-center">
        <div class="tw-mx-0.5 tw-min-w-[45px]">
          <img
            v-if="mention.logo"
            :src="mention.logo"
            class="tw-w-[30px] tw-self-center"
            :alt="$t('global.source_logo')"
          />
        </div>
        <span
          class="tw-cursor-pointer tw-py-4 hover:tw-text-red-400"
          @click="mentionClicked(mention)"
        >
          <slot />
        </span>
        <span
          v-if="mention.syndications.length > 0"
          data-testid="display-syndications-button"
          class="tw-ml-2 tw-text-primary tw-opacity-70"
          @click="displaySyndication = !displaySyndication"
        >
          +{{ mention.syndications.length }}
        </span>
        <span
          class="tw-ml-2 tw-whitespace-nowrap tw-text-[11px] tw-text-primary tw-opacity-50"
        >
          <TimeAgo
            :date="mention.published_at"
            :timezone="mention.source.time_zone"
          />
        </span>
        <div class="tw-relative tw-ml-auto tw-mr-0.5 tw-p-[3px_5px]">
          <Component
            :is="actionSheetComponent"
            :mention="mention"
            button-color="blue-grey"
          />
        </div>
      </div>
      <div
        v-if="displaySyndication"
        data-testid="publishers-list"
        class="tw-ml-[47px] tw-mr-2.5 tw-bg-gray-100 tw-p-1.5"
      >
        <a
          v-for="(syndication, key) in syndications"
          :key="key"
          class="tw-no-shrink group tw-group tw-inline-block tw-w-full tw-cursor-pointer"
          @click="syndicationClicked(syndication)"
        >
          <span class="tw-font-bold group-hover:tw-text-red-400">
            {{ syndication.source_name }}
          </span>
          <span class="tw-float-right tw-text-xs tw-opacity-50">
            {{ formatDate(new Date(syndication.published_at), "h:mmaaa") }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import { formatDate } from "shared/helpers/date";
import { sortedSyndications } from "shared/helpers/mentions";
import { MentionType } from "shared/types/mentions";

import {
  RelatedArticle,
  RelatedMentionCardProps,
  Syndication,
} from "./RelatedMentionCardProps";

const props = defineProps<RelatedMentionCardProps>();

const emit = defineEmits<{
  (e: "syndication:click", item: Syndication): void;
  (e: "mention:click", item: RelatedArticle): void;
}>();

const displaySyndication = ref(false);

const syndications = computed(() => sortedSyndications(props.mention));

const mentionClicked = (mention: RelatedArticle) => {
  emit("mention:click", {
    ...mention,
    excerpts: props.mention.excerpts,
  });
};

const borderClass = computed(() => {
  const type =
    props.mention.type === MentionType.paper_article ? "print" : "online";

  return displaySyndication.value
    ? `border-color-${type} tw-pb-2.5`
    : `border-color-${type}`;
});

const syndicationClicked = (syndication: Syndication) => {
  emit("syndication:click", {
    ...syndication,
    excerpts: props.mention.excerpts,
  });
};
</script>
