import { type AxiosResponse } from "axios";

import useTeamPicker from "shared/composables/useTeamPicker";
import features from "shared/services/features";
import { Extends } from "shared/types";
import {
  type MentionWithSyndication,
  type RadioClip,
  type TvCaption,
  type TvClip,
  type TvLogoAppearance,
  type TvSuper,
} from "shared/types/mentions";
import { isMentionWithSyndications } from "shared/types/mentions/guards";

import ApiClient from "./ApiClient";

type MediaRequestMentionsWithSyndication = Extends<
  MentionWithSyndication,
  TvCaption | TvSuper | RadioClip
>;

export type MediaRequestMention =
  | MediaRequestMentionsWithSyndication
  | TvLogoAppearance
  | TvClip;

export interface MediaRequestDuplicateItem {
  id: number;
  type: "tv_media_request" | "radio_media_request";
}

class MediaRequestService extends ApiClient {
  constructor() {
    super("/media_requests");
  }

  cancel(id: number): Promise<AxiosResponse<"">> {
    return this.put<"">(`/${id}/cancel`);
  }

  findDuplicates(
    mention: MediaRequestMention,
    { seconds }: { seconds: number }
  ): Promise<MediaRequestDuplicateItem[]> {
    const source = [mention.source.id];

    if (isMentionWithSyndications(mention)) {
      source.push(
        ...mention.syndications.map((syndication) => syndication.source_id)
      );
    }

    const timestamp = mention.timestamp || mention.start_time;

    const { selectedTeamPreference } = useTeamPicker();

    const params = {
      start_before: (timestamp + seconds) * 1000,
      start_after: (timestamp - seconds) * 1000,
      type: [
        "caption",
        "tv_caption",
        "tv_super",
        "tv_logo_appearance",
      ].includes(mention.type)
        ? "TvChannel"
        : "RadioStation",
      source,
      ...(features.has("has_team_workspaces")
        ? { organisation_team_id: selectedTeamPreference.value }
        : {}),
    };

    return this.post<MediaRequestDuplicateItem[]>("/find_duplicates", {
      params,
    }).then(({ data }) => data);
  }
}

export default new MediaRequestService();
