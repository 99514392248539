import { Axios } from "axios";

import { v1BaseUrl } from "shared/constants";

/* eslint-disable import/prefer-default-export */
export function updateBaseURL(axiosInstance: Axios, url: string) {
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.baseURL = /^production_[a-z]{2}$/.test(
    import.meta.env.VITE_ENV
  )
    ? `${url}/v1`
    : v1BaseUrl;
}
