import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import {
  ApplicationRecord,
  objectDirtyChecker,
} from "shared/services/spraypaint";
import type { Nullable } from "shared/types";
import { MentionType, SocialMentionType } from "shared/types/mentions";

import StreamFilter from "./StreamFilter";
import StreamGroup from "./StreamGroup";
import StreamNotification from "./StreamNotification";
import StreamSource from "./StreamSource";
import StreamsUser from "./StreamsUser";
import User from "./User";

export enum CaldSource {
  disabled = "disabled",
  enabled = "enabled",
  exclusively = "exclusively",
}

export enum SimpleBooleanFilterType {
  TITLE = "title",
  AUTHOR = "author",
  MEDIUM = "medium",
  PLATFORM = "platform",
  CASE_SENSITIVE = "case_sensitive",
  AT_LEAST = "at_least",
  FIRST = "first",
}

export type SimpleBooleanFilter = {
  type?: SimpleBooleanFilterType;
  value?: string | string[] | number;
  keyword?: string;
};

export interface SimpleBoolean {
  must: SimpleBooleanFilter[];
  should?: SimpleBooleanFilter[];
  must_not?: SimpleBooleanFilter[];
}

@Model()
class Stream extends ApplicationRecord {
  static jsonapiType = "streams";

  @BelongsTo() user: User;

  @HasMany() filters: StreamFilter[];

  @HasOne() group: StreamGroup;

  @HasMany() sources: StreamSource[];

  @HasMany() streamNotifications: StreamNotification[];

  @HasMany() streamsUsers: StreamsUser[];

  @Attr() label: string;

  @Attr() organisationId: number;

  @Attr() userId: number;

  @Attr() groupLabel: string;

  @Attr() color: string;

  @Attr() dashboardEnabled: boolean;

  @Attr() reportEnabled: boolean;

  @Attr() groupId: number;

  @Attr({ persist: false }) usersCount: number;

  @Attr({ persist: false }) activeUsersCount: number;

  @Attr() queryStyle: string;

  @Attr({ persist: false }) isBrief: boolean;

  @Attr() type: number;

  @Attr({ persist: false }) rssUrl: string;

  @Attr() matching: string;

  @Attr({ persist: false }) slug: string;

  @Attr({ persist: false }) archivedAt: Nullable<string>;

  @Attr() expiresAt: Nullable<string>;

  @Attr() booleanQuery: string;

  @Attr() simpleBoolean: Nullable<SimpleBoolean>;

  @Attr() keywords: Nullable<string>;

  @Attr() excludedKeywords: Nullable<string>;

  @Attr() caseSensitive: boolean;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) bookmarksCount: number;

  @Attr() sentimentModeEnabled: boolean;

  @Attr() linkedStreamId: number;

  @Attr() invalidQuery: boolean;

  // content
  @Attr() onlineContent: boolean;

  @Attr() tvContent: boolean;

  @Attr() radioContent: boolean;

  @Attr() printContent: boolean;

  @Attr() magazineContent: boolean;

  @Attr() socialContent: boolean;

  @Attr() podcastContent: boolean;

  @Attr({ dirtyChecker: objectDirtyChecker }) selectedTvContent: MentionType[];

  @Attr({ dirtyChecker: objectDirtyChecker })
  selectedRadioContent: MentionType[];

  @Attr({ dirtyChecker: objectDirtyChecker })
  selectedOnlineContent: MentionType[];

  @Attr({ dirtyChecker: objectDirtyChecker })
  selectedPrintContent: MentionType[];

  @Attr({ dirtyChecker: objectDirtyChecker })
  selectedMagazineContent: MentionType[];

  @Attr({ dirtyChecker: objectDirtyChecker })
  selectedSocialContent: SocialMentionType[];

  @Attr({ dirtyChecker: objectDirtyChecker })
  selectedPodcastContent: MentionType[];

  // filters
  @Attr() onlineTier: boolean;

  @Attr() tvTier: boolean;

  @Attr() radioTier: boolean;

  @Attr() printTier: boolean;

  @Attr() magazineTier: boolean;

  @Attr() socialTier: boolean;

  @Attr() podcastTier: boolean;

  @Attr() socialBookmarksStream: boolean;

  @Attr() socialImpactThreshold: number;

  @Attr() socialOnlyVerified: boolean;

  @Attr() internationalPlus: boolean;

  @Attr() internationalMax: boolean;

  @Attr() includeOnlineCaldSources: CaldSource;

  @Attr() includePrintCaldSources: CaldSource;

  // extra
  @Attr({ persist: false }) scheduledReportSpecificationsCount: number;

  @Attr({ persist: false }) notificationsCounts: number;
}

export default Stream;
