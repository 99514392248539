import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import Organisation from "./Organisation";
import User from "./User";

export enum Status {
  "staged" = "staged",
  "processing" = "processing",
  "completed" = "completed",
  "failed" = "failed",
}

enum ImportType {
  contact_lists = 1,
  private_influencers = 2,
  private_outlets = 3,
  public_outlets = 4,
  opt_out_emails = 5,
  contact_lists_contacts = 6,
  user_private_influencers = 7,
  bookmark_streams = 8,
  bookmark_external_items = 9,
}

@Model()
class OutreachMigratorImport extends ApplicationRecord {
  static jsonapiType = "outreach_migrator_imports";

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @BelongsTo() defaultUser: User;

  @Attr() status: Status;

  @Attr() error: string;

  @Attr() errorMessage: string;

  @Attr() importType: ImportType;

  @Attr() invalidRecordsCsvUrl: string;

  @Attr() validRecordCount: number;

  @Attr() invalidRecordCount: number;

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() defaultUserId: number;

  @Attr() defaultUserName: string;

  @Attr() options: Record<string, any>;

  @HasMany() activeStorageAttachments: ActiveStorageAttachment[];

  @HasMany({ persist: false }) importFiles: ActiveStorageAttachment[];

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) completedAt: string;
}

export default OutreachMigratorImport;
